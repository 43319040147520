//存储token
export const setJwt = (jwt) => {
  localStorage.setItem("jwt", jwt);
};
//获取token
export const getJwt = () => {
  return localStorage.getItem("jwt");
};

//清除本地存储的token
export const removeJwt = () => {
  localStorage.removeItem("jwt");
}