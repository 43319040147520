// 数据
const state = {
    category: String,
    resource_category: '',
    index_category: 'all',
    rank_category: 'all',
    history_category: 'all',
    favorite_category: 'all',
    search_category: 'all',
    aliveName: '',
    prevRouter: null,
    screenWidth: window.innerWidth,
    isMobileDevice: / Android | webOS | iPhone | iPad | iPod | BlackBerry | IEMobile | Opera Mini/i.test(navigator.userAgent)

};
//修改数据
const mutations = {
    edit_isMobileDevice(state, data) {
        state.isMobileDevice = data;
    },
    edit_screenWidth(state, data) {
        state.screenWidth = data;
    },
    edit_prevRouter(state, data) {
        state.prevRouter = data;
    },
    edit_category(state, data) {
        state.category = data;
    },
    edit_resource_category(state, data) {
        state.resource_category = data;
    },
    edit_index_category(state, data) {
        state.index_category = data;
    },
    edit_search_category(state, data) {
        state.search_category = data;
    },
    edit_rank_category(state, data) {
        state.rank_category = data;
    },
    edit_history_category(state, data) {
        state.history_category = data;
    },
    edit_favorite_category(state, data) {
        state.favorite_category = data;
    },
    edit_aliveName(state, data) {
        state.aliveName = data;
    },
};
//异步操作逻辑
const actions = {

};
//计算属性
const getters = {};

export default {
    state,
    mutations,
    actions,
    getters
}