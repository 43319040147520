<template>
  <v-dialog content-class="regulation" :value="value" @input="$emit('update:value', $event)" width="700">
    <v-card>
      <v-card-title class="">用户须知</v-card-title>
      <v-card-text>
        <p>尊敬的用户：</p>
        <p>
          欢迎您加入nicohub。为了确保社区的友好、安全和积极的氛围，我们制定了以下用户守则，请您遵守以维护社区的良好环境。
        </p>
        <h3>社区行为准则：</h3>
        <ol>
          <li>
            -
            禁止仇恨和歧视：我们坚决反对任何形式的仇恨言论、种族歧视、宗教歧视、性别歧视或其他歧视行为。请避免发布任何带有歧视色彩的言论，尊重他人的种族、宗教、性别、性取向、国籍等个人特征。
          </li>
          <li>
            -
            禁止政治言论：为了维持社区的中立性和和谐氛围，我们禁止在社区中发布与政治相关的内容。请避免讨论政治话题、发布政治宣传或进行政治争论。
          </li>
          <li>
            -
            禁止广告和恶意信息：请不要在社区中发布广告、推广链接、恶意信息或任何形式的垃圾信息。我们鼓励用户分享有价值的内容，并保持社区的信息质量和可信度。
          </li>
        </ol>
        <h3>账号安全及滥用：</h3>
        <ol>
          <li>
            -
            不共享账号：为了保护您的个人信息和账号安全，请不要与他人共享您的社区账号。您应当对自己的账号和密码负责，并采取适当的措施保护其安全性。
          </li>
          <li>
            -
            密码保护：请设置一个强密码，并定期更改密码以确保账号的安全性。避免使用与您其他在线账号相同的密码，以防止账号信息泄露。
          </li>
          <li>- 禁止任何形式的爬虫在短时间内大量下载/引用资源</li>
        </ol>
        <br />
        <p>
          我们希望您能遵守以上守则，共同营造一个友好、安全、包容的社区。如若违反守则，我们将采取必要的措施，包括但不限于警告、暂时或永久禁言，甚至封禁账号。如发现任何违规行为，请及时向管理员或社区管理团队举报。
        </p>
        <p>谢谢您的理解和支持，祝您使用愉快！</p>
        <p>nicohub管理团队</p>
      </v-card-text>
    </v-card>
    <div class="dialog__action">
      <v-btn @click="$emit('update:value', false)" color="#e91e63" style="padding: 0 18px">
        我已知晓
      </v-btn>
    </div>
  </v-dialog>
</template>
<script>
import bus from "@/store/bus";
export default {
  name: "Regulation",
  mixins: [],
  props: ["value", "closeAnnouncement"],
  data() {
    return {};
  },
  components: {},
  computed: {},
  methods: {},
  created() { },
  mounted() { },

  beforeUpdate() { },
  destroyed() { },
  watch: {},
};
</script>
<style lang="scss" scoped>
::v-deep .regulation {
  position: relative;
  max-height: 80% !important;
  margin: 10px !important;
  overflow: hidden;
  overflow-y: hidden !important;

  .v-card {
    overflow-y: auto;
    min-height: 360px;
    max-height: 70vh;

    /* 滚动条整体样式 */
    &::-webkit-scrollbar {
      width: 8px;
    }

    /* 滚动条轨道样式 */
    &::-webkit-scrollbar-track {
      background-color: rgba(255, 255, 255, 0.1);
      /* 半透明的白色背景 */
    }

    /* 滚动条滑块样式 */
    &::-webkit-scrollbar-thumb {
      background-color: rgba(128, 128, 128, 0.7);
      /* 半透明的灰色滑块 */
      border-radius: 4px;
      /* 设置滑块的圆角 */
    }

    /* 滚动条滑块在悬停时的样式 */
    &::-webkit-scrollbar-thumb:hover {
      background-color: rgba(0, 0, 0, 0.7);
      /* 半透明的黑色滑块 */
    }
  }

  .v-card__title {
    font-weight: bold !important;
    padding: 25px 24px 20px !important;
  }

  .v-card__text {}

  .v-btn {
    width: 100%;
  }

  .v-stepper {
    padding-bottom: 70px;

    .v-stepper__step {
      padding: 24px 16px 16px;

      .v-stepper__label {
        font-weight: bold;
        letter-spacing: 1px;
      }
    }

    .v-stepper__content {
      padding: 16px 46px 16px 23px;
      margin: -8px -36px -16px 28px !important;
    }
  }

  .dialog__action {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0.6rem;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    background-color: #424242;
    border-color: rgba(255, 255, 255, 0.1) rgba(255, 255, 255, 0.07) rgba(255, 255, 255, 0.04);
    border-radius: 0.25rem;
    border-style: solid;
    border-width: 1px;
  }
}

h3 {
  color: #ededed;
  margin: 10px 0;
}
</style>
