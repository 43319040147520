<template>
  <div class="popup-wrapper">
    <div class="popup-container">
      <div class="header-container">
        <div class="header">
          <div class="avatar" v-lazy-container="{ selector: 'img' }">
            <img :data-src="userAvatar" :data-error="userErrImg" alt="" />
          </div>
          <div class="user-info" v-if="user_info != null">
            <div class="user-name">
              {{ user_info.name }}
              <!--<div class="n-vip">VIP</div>-->
            </div>

            <div class="exp">
              <div class="exp-info">
                <div class="level mgr-10">
                  {{ user_info.level.level_name }}
                </div>
                <div class="now-exp">
                  <span class="d-p1">{{ user_info.level.exp }}</span> /
                  {{ user_info.level.next_exp }}
                </div>
              </div>
              <v-progress-linear :value="expLinear" height="6" color="#ff8b66">
              </v-progress-linear>
            </div>
          </div>
        </div>
      </div>

      <div class="section-list">
        <router-link class="section-item" :to="{ name: 'Setting' }">
          <v-icon size="20">mdi-account</v-icon>
          <span>个人设置</span>
        </router-link>
        <router-link class="section-item" :to="{ name: 'SysNotice' }">
          <v-icon size="20">mdi-bell</v-icon>
          <span>通知消息</span>
        </router-link>
        <div class="section-item" @click="exit">
          <v-icon size="20">mdi-exit-to-app</v-icon>
          <span>退出登录</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { exit } from "@/api/request/user";
import bus from "@/store/bus";

export default {
  name: "head_popup",
  mixins: [],
  data() {
    return {};
  },
  computed: {
    user_info() {
      return this.$store.getters.user_info;
    },
    expInfo() {
      return this.$store.getters.user_info.exp_info
        ? this.$store.getters.user_info.exp_info
        : {};
    },
    expLinear: {
      get: function () {
        return this.user_info.level.exp / (this.user_info.level.next_exp / 100);
      },
      set: function () { },
    },
  },
  methods: {
    exit() {
      let res = exit.call(this);
    },
  },
  created() { },
  mounted() { },
};
</script>
<style lang="scss" scoped>
$avatarWidth: 40px;

.popup-wrapper {
  background-color: #f2f2f2;
  box-shadow: 0 14px 28px rgb(0 0 0 / 25%), 0 10px 10px rgb(0 0 0 / 22%);
  z-index: 6;
  position: absolute;
  right: 0;
  top: 64px;
  border-radius: 10px;
  border: none;
  backdrop-filter: blur(8px);

  .popup-container {
    width: 270px;

    .header-container {
      border-bottom: 1px solid #d3d3d3;

      .header {
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 10px 0;
        background-color: transparent !important;
        padding: 1rem;

        .avatar {
          width: 70px;
          height: 70px;
          cursor: pointer;
          margin: 0;
          border-radius: 50%;
          border: 3px solid #fff;
          background-color: transparent;
          overflow: hidden;

          img {
            display: block;
            width: 100%;
            height: 100%;
          }
        }

        .user-info {
          width: 100%;
          text-align: center;

          .user-name {
            color: #3f3f3f;
            padding: 4px 0;
            font-size: 1.2rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .n-vip {
            width: 44px;
            margin: 0 auto;
            padding: 0 2px;
            font-size: 0.7rem;
            font-weight: 600;
            letter-spacing: 1px;
            border-radius: 11px;
            background-color: #e45a81;
            color: white;
          }

          .exp {
            padding: 0 20px;

            .exp-info {
              display: flex;
              padding: 4px 0;
              padding-top: 12px;
              color: #666666;

              .level {
                font-size: 0.8rem;
              }

              .now-exp {
                font-size: 0.8rem;
                margin-left: auto;
              }
            }

            ::v-deep .el-progress {
              .el-progress-bar {
                padding: 0;
                margin-right: 0;
              }
            }
          }
        }
      }
    }

    .section-list {
      padding: 0.5rem 0;

      .section-item {
        &:hover {
          background-color: #d3d3d3;
        }

        padding: 0 1.8rem;
        cursor: pointer;
        $height: 40px;
        display: flex;
        align-items: center;
        height: $height;

        span {
          color: #676767;
          line-height: $height;
        }

        .v-icon {
          color: #676767;
          margin-right: 10px;
        }
      }
    }
  }
}
</style>