import axios from "axios";
import qs from "qs";
import nprogress from "nprogress";
import bus from "@/store/bus";
import router from "@/router";
import { setJwt, getJwt, removeJwt } from "@/utils/jwt";
import "nprogress/nprogress.css";

function uniqueId(prefix) {
  return `${prefix}_` + Math.random().toString(36).substr(2, 9);
}

const requests = axios.create({
  baseURL: "/api",
  timeout: 5000,
});

requests.interceptors.request.use((config) => {
  config.headers["Authorization"] = getJwt();

  if (config.data && config.data.uploadAvatar) {
    console.log();
    let formData = new FormData();
    // 将Blob添加到FormData
    formData.append("file", config.data.file, config.data.imgName);
    config.data = formData;
    config.headers["Content-Type"] = "multipart/form-data";
    nprogress.start();
    return config;
  }

  config.headers["Content-Type"] =
    "application/x-www-form-urlencoded;charset=UTF-8";

  // 判断是不是验证码接口，由于后端直接传回图片，需做特殊处理
  if (config.url == "captcha/generate/") {
    config["responseType"] = "arraybuffer";
  }

  // 判断这段数据是否需要QS，所有不需要QS的数据用NOQS：{}包裹
  const isObj = typeof config.data === "object"; // 先判断是不是对象
  if (isObj) {
    const data = Object.keys(config.data)[0];
    if (data != "NOQS") {
      config.data = qs.stringify(config.data);
    } else {
      config.data = config.data.NOQS;
    }
  } else {
    config.data = qs.stringify(config.data);
  }

  nprogress.start();
  return config;
});

requests.interceptors.response.use(
  (res) => {
    // 判断获取的数据是否为空，或者不是json
    // 如果获取的是验证码 则不需要做这样的判断
    if (
      res.status == 200 &&
      res.config.url != "captcha/generate/" &&
      (res.data == null ||
        res.data == "" ||
        typeof res.data != "object" ||
        Object.keys(res.data).length == 0)
    ) {
      // 返回一个 Promise.reject()，触发重试机制
      return Promise.reject({
        response: {
          data: "响应数据为空",
        },
      });
    } else {
      nprogress.done();
      return res;
    }
  },
  (error) => {
    if (error.response.status == 403) {
      router.push("/restricte_daccess");
      return;
    }
    // 添加重试次数
    const maxRetries = 1; // 最大重试次数
    let retryCount = 0; // 当前重试次数

    const retryRequest = () => {
      retryCount++;

      if (retryCount <= maxRetries) {
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve(axios(error.config));
          }, 3000);
        }).catch((error) => {
          return retryRequest(); // 重试请求
        });
      } else {
        bus.$emit("errorMessage", {
          id: uniqueId("item_"),
          color: "error",
          message: "网络错误",
        });
        return Promise.reject(error);
      }
    };

    return retryRequest();
  }
);

export default requests;
