<template>
  <v-dialog content-class="announcement" :value="value" @click:outside="closeAnnouncement"
    @input="$emit('update:value', $event)" width="700">
    <v-card id="scroll-card" v-scroll:#scroll-card="onScroll">
      <v-card-title class="">重要公告</v-card-title>
      <v-stepper v-model="e6" vertical non-linear min-height="360" @change="changeHeight">
        <template v-for="(item, index) in content">
          <v-stepper-step :step="index + 1" editable edit-icon="">
            {{ item.title }}</v-stepper-step>
          <v-stepper-content :step="index + 1">
            <v-card-text>
              <template v-for="(item2, index) in item.paragraph">
                <div :key="index">
                  <div v-if="item2.subTitle" class="d-subtitle" v-html="item2.subTitle"></div>
                  <div v-html="item2.content"></div>
                </div>
              </template>
            </v-card-text>
          </v-stepper-content>
        </template>
      </v-stepper>

      <v-divider></v-divider>
    </v-card>
    <div class="announ-fold" v-show="cardScrollTop < scrollableHeight - 10">
      <v-icon size="60" color="#e91e63">mdi-menu-down</v-icon>
    </div>
    <div class="dialog__action">
      <v-btn @click="closeAnnouncement" color="#e91e63" style="padding: 0 18px">
        我已知晓
      </v-btn>
    </div>
  </v-dialog>
</template>
<script>
import { waitForElement } from "@/utils/tools";
import content from "@/components/ui/features/announcement/announcement.json";
import bus from "@/store/bus";
export default {
  name: "Announcement",
  mixins: [],
  props: ["value", "closeAnnouncement"],
  data() {
    return {
      e6: 1,
      cardScrollTop: 0,
      scrollableHeight: 0,
    };
  },
  components: {},
  computed: {
    content() {
      return content;
    },
  },
  methods: {
    onScroll(e) {
      this.cardScrollTop = e.target.scrollTop;
    },
    changeHeight() {
      this.scrollableHeight = 0;
      setTimeout(() => {
        let scroll_card = document.getElementById("scroll-card");
        this.scrollableHeight =
          scroll_card.scrollHeight - scroll_card.clientHeight;
      }, 800);
    },
  },
  created() { },
  mounted() {
    waitForElement("#scroll-card", () => {
      let scroll_card = document.getElementById("scroll-card");
      this.$nextTick(() => {
        this.scrollableHeight =
          scroll_card.scrollHeight - scroll_card.clientHeight;
      })

    })
  },

  beforeUpdate() {

  },
  destroyed() { },
  watch: {},
};
</script>
<style lang="scss" scoped>
// 公告
::v-deep .announcement {
  position: relative;
  max-height: 80% !important;
  margin: 10px !important;
  overflow: hidden;
  overflow-y: hidden !important;

  .v-card {
    overflow-y: auto;
    min-height: 360px;
    max-height: 70vh;

    /* 滚动条整体样式 */
    &::-webkit-scrollbar {
      width: 8px;
    }

    /* 滚动条轨道样式 */
    &::-webkit-scrollbar-track {
      background-color: rgba(255, 255, 255, 0.1);
      /* 半透明的白色背景 */
    }

    /* 滚动条滑块样式 */
    &::-webkit-scrollbar-thumb {
      background-color: rgba(128, 128, 128, 0.7);
      /* 半透明的灰色滑块 */
      border-radius: 4px;
      /* 设置滑块的圆角 */
    }

    /* 滚动条滑块在悬停时的样式 */
    &::-webkit-scrollbar-thumb:hover {
      background-color: rgba(0, 0, 0, 0.7);
      /* 半透明的黑色滑块 */
    }
  }

  .v-card__title {
    font-weight: bold !important;
    padding: 25px 24px 20px !important;
  }

  .v-card__text {
    padding: 0;
  }

  .v-btn {
    width: 100%;
  }

  .v-stepper {
    padding-bottom: 70px;

    .v-stepper__step {
      padding: 24px 16px 16px;

      .v-stepper__label {
        font-weight: bold;
        letter-spacing: 1px;
      }
    }

    .v-stepper__content {
      padding: 16px 46px 16px 23px;
      margin: -8px -36px -16px 28px !important;
    }
  }

  .dialog__action {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0.6rem;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    background-color: #424242;
    border-color: rgba(255, 255, 255, 0.1) rgba(255, 255, 255, 0.07) rgba(255, 255, 255, 0.04);
    border-radius: 0.25rem;
    border-style: solid;
    border-width: 1px;
  }
}

.d-subtitle {
  margin: 14px 0 14px 0;
  font-size: 0.98rem;
  color: #ffa3a3;
}

.d-highlight {
  color: #eb4343;
}

.announ-fold {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 60px;
  width: max-content !important;
  animation: floating 1s ease-in-out infinite;
}

@keyframes floating {
  0% {
    transform: translate(-50%, 0);
  }

  50% {
    transform: translate(-50%, -10px);
  }

  100% {
    transform: translate(-50%, 0);
  }
}
</style>
