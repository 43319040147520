import { } from "@/api";
//数据
const state = {
    filter_params: {},
    index_nowPage: 1,
};
//修改数据
const mutations = {
    edit_index_nowPage(state, data) {
        state.index_nowPage = data;
    },
    edit_filter_params(state, data) {
        state.filter_params = data;
    },
};
//异步操作逻辑
const actions = {};
//计算属性
const getters = {};

export default {
    state,
    mutations,
    actions,
    getters,
};
