import {
  get_Data,
  get_Info_Data,
  get_Chapter_Data,
  get_Chapter_Page,
  get_Search_Data,
  get_Hot_Word,
  get_comment,
  create_comment,
  report_Comment,
  get_rank,
  get_category_list
} from "@/api";
import { getJwt } from "@/utils/jwt";
import { set } from "nprogress";

//数据
const state = {
  jwt: getJwt(),
  list_data: [],
  rank_list_data: [],
  search_list_data: [],
  info_data: [],
  category_list: {},
  chapter_data: [],
  chapter_page: [],
  mobile_sub_reply_data: [],
  mobile_root_reply_data: [],
  error_message: [],
  nowpage: 1,
};
//修改数据
const mutations = {
  edit_user_info(state, data) {
    state.user_info = data;
  },
  edit_list_data(state, data) {
    state.list_data = data;
  },
  edit_rank_list_data(state, data) {
    state.rank_list_data = data;
  },
  edit_search_list_data(state, data) {
    state.search_list_data = data;
  },
  edit_info_data(state, data) {
    state.info_data = data;
  },
  edit_chapter_data(state, data) {
    //替换data数组内的键名，pic替换成src,width替换成w,height替换成h
    state.chapter_data = data;
  },
  edit_chapter_page(state, data) {
    state.chapter_page = data;
  },
  edit_hot_word(state, data) {
    state.hot_word = data;
  },
  edit_category_list(state, data) {
    state.category_list = data;
  },
  edit_mobile_root_reply_data(state, data) {
    state.mobile_root_reply_data = data;
  },
  edit_mobile_sub_reply_data(state, data) {
    state.mobile_sub_reply_data = data;
  },
  edit_error_message(state, data) {
    state.error_message = data;
  },
  edit_nowpage(state, data) {
    state.nowpage = data;
  },
};
//异步操作逻辑
const actions = {
  async get_Data({ commit }, data) {
    let result = await get_Data(data);
    if (result.data.code == 200) {
      commit("edit_list_data", result.data);
      return Promise.resolve(result.data);
    } else {
      return Promise.reject({
        code: result.data.code,
        info: new Error(result.data.msg),
      });
    }
  },
  async get_Info_Data({ commit }, data) {
    let result = await get_Info_Data(data);
    if (result.data.code == 200) {
      commit("edit_info_data", result.data.data);
      return Promise.resolve(result.data);
    } else {
      return Promise.reject({
        code: result.data.code,
        info: new Error(result.data.msg),
      });
    }
  },
  async get_Chapter_Data({ commit }, data) {
    let result = await get_Chapter_Data(data);
    if (result.data.code == 200) {
      commit("edit_chapter_data", result.data.data);
      return Promise.resolve(result.data);
    } else if (result.data.code == 400) {
      return null;
    } else {
      return Promise.reject({
        code: result.data.code,
        info: new Error(result.data.msg),
      });
    }
  },
  async get_Chapter_Page({ commit }, data) {
    let result = await get_Chapter_Page(data);
    if (result.data.code == 200) {
      commit("edit_chapter_page", result.data);
      return Promise.resolve(result.data);
    } else {
      commit("edit_chapter_page", {});
      return Promise.reject({
        code: result.data.code,
        info: new Error(result.data.msg),
      });
    }
  },
  async get_filter_Data({ commit }, data) {
    let result = await get_Search_Data(data);
    if (result.data.code == 200) {
      commit("edit_list_data", result.data);
      return Promise.resolve(result.data);
    } else if (result.data.code == 404) {
      commit("edit_list_data", {});
      return Promise.resolve(result.data);
    } else {
      commit("edit_list_data", {});
      return Promise.reject({
        code: result.data.code,
        info: new Error(result.data.msg),
      });
    }
  },
  async get_Search_Data({ commit }, data) {
    let result = await get_Search_Data(data);
    if (result.data.code == 200) {
      commit("edit_search_list_data", result.data);
      return Promise.resolve(result.data);
    } else if (result.data.code == 404) {
      commit("edit_search_list_data", {});
      return Promise.resolve(result.data);
    } else {
      commit("edit_search_list_data", {});
      return Promise.reject({
        code: result.data.code,
        info: new Error(result.data.msg),
      });
    }
  },
  async get_rank({ commit }, data) {
    let result = await get_rank(data);
    if (result.data.code == 200) {
      commit("edit_rank_list_data", result.data);
      return Promise.resolve(result.data);
    } else if (result.data.code == 404) {
      commit("edit_rank_list_data", {});
      return Promise.resolve(result.data);
    } else {
      commit("edit_rank_list_data", {});
      return Promise.reject({
        code: result.data.code,
        info: new Error(result.data.msg),
      });
    }
  },
  async get_Hot_Word({ commit }, data) {
    let result = await get_Hot_Word(data);
    if (result.data.code == 200) {
      commit("edit_hot_word", result.data);
    } else {
      commit("edit_hot_word", {});
      return Promise.reject({
        code: result.data.code,
        info: new Error(result.data.msg),
      });
    }
  },
  async get_category_list({ commit }, data) {
    let result = await get_category_list(data);
    if (result.data.code == 200) {
      commit("edit_category_list", result.data);
    } else {
      commit("edit_category_list", {});
      return Promise.reject({
        code: result.data.code,
        info: new Error(result.data.msg),
      });
    }
  },
  async create_comment({ commit }, data) {
    let result = await create_comment(data);
    if (result.data.code == 200) {
      return Promise.resolve(result.data);
    } else {
      return Promise.reject({
        code: result.data.code,
        info: new Error(result.data.msg),
      });
    }
  },
  async get_comment({ commit }, data) {
    let result = await get_comment(data);
    if (result.data.code == 200) {
      return Promise.resolve(result.data);
    } else {
      return Promise.reject({
        code: result.data.code,
        info: new Error(result.data.msg),
      });
    }
  },
  async report_Comment({ commit }, data) {
    let result = await report_Comment(data);
    if (result.data.code == 200) {
      return Promise.resolve(result.data);
    } else {
      return Promise.reject({
        code: result.data.code,
        info: new Error(result.data.msg),
      });
    }
  },
};
//计算属性
const getters = {};

export default {
  state,
  mutations,
  actions,
  getters,
};
