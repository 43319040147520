import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

Vue.use(VueRouter);
const routes = [
  //登录页
  {
    path: "/login",
    name: "Login",
    meta: {
      title: "登录",
    },
    component: () => import("@/views/login/Index"),
  },
  //注册页
  {
    path: "/register",
    name: "Register",
    meta: {
      title: "用户注册",
      jumpRequiresAuth: false,
    },
    component: () => import("@/views/register/Index"),
  },
  //找回密码页
  {
    path: "/forget",
    name: "Forget",
    meta: {
      title: "找回密码",
      jumpRequiresAuth: false,
    },
    component: () => import("@/views/forget/Index"),
  },
  //主页
  {
    path: "/index",
    name: "Index",
    meta: {
      title: "首页",
      keepAlive: true,
    },
    component: () => import("@/views/index/Index"),
    props: (route) => ({ query: route.query }),
  },
 
  //漫画Info页
  {
    path: "/comic/info/:id",
    name: "ComicInfo",
    meta: {
      keepRead: false,
      isInfo: true,
      infoKeepAlive: true,
    },
    component: () => import("@/views/comic/Index"),
    children: [
      {
        path: "player",
        name: "ComicPlayer",
        meta: {
          isPlayer: true,
          isInfo: true,
          noScroll: true,
        },
        component: () => import("@/components/comic/Play"),
        // 判断有没有传给播放器路由的参数，如果有就前往，没有则回到对应资源的info页面
        beforeEnter: (to, from, next) => {
          if (!to.params.toPlay) {
            next({ name: "ComicInfo", params: { id: to.params.id } });
          } else {
            next();
          }
        },
      },
    ],
  },
  //动画info页
  {
    path: "/anime/info/:id",
    name: "AnimeInfo",

    meta: {
      duties: "director",
      keepRead: false,
      isInfo: true,
      infoKeepAlive: true,
    },
    component: () => import("@/views/anime/Index"),
    children: [
      {
        path: "player",
        name: "AnimePlayer",
        meta: {
          isPlayer: true,
          isInfo: true,
          noScroll: true,
        },
        component: () => import("@/components/anime/Player"),

        // 判断有没有传给播放器路由的参数，如果有就前往，没有则回到对应资源的info页面
        beforeEnter: (to, from, next) => {
          if (!to.params.toPlay) {
            next({ name: "AnimeInfo", params: { id: to.params.id } });
          } else {
            next();
          }
        },
      },
    ],
  },
  // 音声info页
  {
    path: "/sound/info/:id",
    name: "SoundInfo",
    meta: {
      keepRead: false,
      isInfo: true,
      infoKeepAlive: true,
    },
    component: () => import("@/views/sound/Index"),
    children: [
      {
        path: "player",
        name: "SoundPlayer",
        meta: {
          isPlayer: true,
          isInfo: true,
          noScroll: true,
        },
        // component: () => import("@/components/sound/Player"),
        // 判断有没有传给播放器路由的参数，如果有就前往，没有则回到对应资源的info页面
        beforeEnter: (to, from, next) => {
          if (!to.params.toPlay) {
            next({ name: "SoundInfo", params: { id: to.params.id } });
          } else {
            next();
          }
        },
      },
    ],
  },
  
  {
    path: "/novel/info/:id",
    name: "NovelInfo",
    meta: {
      keepRead: false,
      isInfo: true,
      infoKeepAlive: true,
    },
    component: () => import("@/views/novel/Index"),
    children: [
      {
        path: "player",
        name: "NovelPlayer",
        meta: {
          isPlayer: true,
          isInfo: true,
          noScroll: true,
        },
        component: () => import("@/components/novel/NovelReader"),

        // 判断有没有传给播放器路由的参数，如果有就前往，没有则回到对应资源的info页面
        beforeEnter: (to, from, next) => {
          if (!to.params.toPlay) {
            next({ name: "NovelInfo", params: { id: to.params.id } });
          } else {
            next();
          }
        },
      },
    ],
  },
  {
    path: "/search",
    name: "Search",
    meta: {
      keepAlive: true,
    },
    component: () => import("@/views/search/Index"),
    props: (route) => ({ query: route.query.query }),
  },
  {
    path: "/account",
    name: "Account",
    meta: {
      title: "用户中心",
      requiresAuth: true,
      jumpRequiresAuth: true,
    },
    component: () => import("@/views/account/Index"),
    children: [
      {
        path: "setting",
        name: "Setting",
        meta: {
          title: "个人设置",
          requiresAuth: true,
          jumpRequiresAuth: true,
        },
        component: () => import("@/views/account/Setting"),
      },
      {
        path: "sysNotice",
        name: "SysNotice",
        meta: {
          title: "系统消息",
          requiresAuth: true,
          jumpRequiresAuth: true,
        },
        component: () => import("@/views/account/SysNotice"),
      },
      {
        path: "replyMe",
        name: "ReplyMe",
        meta: {
          title: "回复我的",
          requiresAuth: true,
          jumpRequiresAuth: true,
        },
        component: () => import("@/views/account/ReplyMe"),
      },
    ],
  },
  {
    path: "/history",
    name: "History",
    component: () => import("@/views/account/History"),
    meta: {
      title: "历史记录",
      keepAlive: true,
    },
  },
  {
    path: "/favorite",
    name: "Favorite",
    meta: {
      title: "我的收藏",
      keepAlive: true,
      requiresAuth: true,
      jumpRequiresAuth: true,
    },
    component: () => import("@/views/account/Favorite"),
  },
  {
    path: "/rank",
    name: "Rank",
    component: () => import("@/views/rank/Index"),
    props: (route) => ({ r_type: route.query.type, r_date: route.query.date }),
    meta: {
      keepAlive: true,
    },
    children: [],
  },

  // 用户根据链接修改密码
  {
    path: "/user/changepassword",
    name: "ChangePassword",
    meta: {
      title: "密码修改",
      jumpRequiresAuth: false,
    },
    component: () => import("@/views/user/ChangePassword"),
  },
  // 用户根据链接邮箱验证
  {
    path: "/user/email_verify",
    name: "EmailVerify",
    meta: {
      title: "邮箱验证",
      jumpRequiresAuth: false,
    },
    component: () => import("@/views/user/EmailVerify"),
  },
  // 频繁访问提示页
  {
    path: "/restricte_daccess",
    name: "RestrictedAccess",
    meta: {
      title: "访问错误",
      jumpRequiresAuth: false,
    },
    component: () => import("@/views/tools/RestrictedAccess"),
  },
  //重定向到主页
  {
    path: "/",
    redirect: "/index",
  },
  //其他错误的url重定向到主页
  {
    path: "*",
    redirect: "/index",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  // 获取保存的滚动距离
  const scrollPosition = JSON.parse(sessionStorage.getItem("scrollPosition"));
  // 根据fullPath判断是否是后退，
  if (!scrollPosition || to.fullPath != scrollPosition.fromPath) {
    const currentPosition =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop;
    to.meta.scrollPosition = currentPosition;

    sessionStorage.setItem(
      "scrollPosition",
      JSON.stringify({
        position: currentPosition.toString(),
        fromPath: from.fullPath,
      })
    );
  }
  let isLogin = sessionStorage.getItem("isLogin");
  // 路由鉴权，前往需要登录的页面时作相应操作
  if (to.meta.jumpRequiresAuth) {
    if (isLogin == "false") {
      next({ path: "/login" });
      return;
    }
  }

  // 登录后跳转到原先页面
  if (to.name == "Login") {
    if (from.name == null || from.meta.jumpRequiresAuth == false) {
      next();
      return;
    }
    store.commit("edit_prevRouter", from.fullPath);
  } else {
    store.commit("edit_prevRouter", null);
  }

  // 设置title
  const pageTitle = to.meta.title;
  if (pageTitle) {
    document.title = pageTitle + " - " + process.env.VUE_APP_WEBSITE_NAME;
  }
  // 清除滚动限制
  document.querySelector("html").style.overflow = "";

  next();
});
router.afterEach((to, from) => {
  // 后退保留滚动判断
  const scrollPosition = JSON.parse(sessionStorage.getItem("scrollPosition"));
  if (scrollPosition && scrollPosition.fromPath == to.fullPath) {
    window.scrollTo({ top: scrollPosition.position });
  } else {
    if (to.meta.isPlayer != true) {
      window.scrollTo({
        top: 0,
      });
    }
  }
});
export default router;
