import bus from '@/store/bus';

export async function get_User() {
    try {
        let res = await this.$store.dispatch('get_User');
        // 传登录状态

        // 缓存登录状态和用户信息到本地
        sessionStorage.setItem("isLogin", true)
        localStorage.setItem("local_user_info", JSON.stringify(res.data));
        this.$store.commit('edit_isLogin', true);

        // 将登录状态和用户信息写入vuex
        this.$store.commit("edit_user_info", res.data);
        this.$store.commit("edit_user_avatar", res.data.avatar);
        return res;

    } catch (error) {
        this.handleError(error, false)
        return error;
    }
}
export async function user_change_password(params) {
    try {
        let res = await this.$store.dispatch('user_change_password', params);
        return res;
    } catch (error) {
        this.handleError(error)
        return error;
    }
}
export async function email_verify(params) {
    try {
        let res = await this.$store.dispatch('email_verify', params);
        return res;
    } catch (error) {
        this.handleError(error)
        return error;
    }
}

export async function exit() {
    try {
        let res = await this.$store.dispatch('exit');

        bus.$emit("snackbar", {
            show: true,
            content: '成功登出'
        })
        // 清空vuex
        this.clearLoginStatus();
        if (this.$route.meta.requiresAuth) {
            this.$router.push('/index');
        }

    } catch (error) {
        this.handleError(error)
        return error;
    }
}