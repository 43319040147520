import bus from '@/store/bus';
export async function get_Search_Data(params) {
    let query = params.category != '' ? 'board:' + params.category + ' ' + params.query : params.query;
    try {
        let res = await this.$store.dispatch('get_Search_Data', {
            query: query,
            page: params.page
        })
        return res;
    } catch (error) {
        this.handleError(error);
        return error;
    }
}
export async function get_Hot_Word(category) {
    try {
        if (category == null) category = 'video';
        let res = await this.$store.dispatch('get_Hot_Word', {
            category
        });
        return res;
    } catch (error) {
        this.handleError(error);
        return error;
    }
}