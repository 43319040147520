import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from './router'
import VueMeta from 'vue-meta'
//import {reqLogin} from '@/api';
import store from '@/store'
import { snackbar, dialog } from 'vuetify2-expand'
import Page from '@/components/page/Index'
import VuetifySnackbarQueue from 'vuetify-snackbar-queue'
import VueLazyLoad from 'vue-lazyload'
import VueCropper from 'vue-cropper'
import { storage } from '@/utils/tools'
import axios from "axios";
import config from "@/config/config";
// Hls
 

// mixins
import globalMixins from '@/mixins/globalMixins';

// v-bind
import '@/directives/directives.js'

import '@/scss/common.scss'
import '@/scss/style.scss'
import '@/scss/m-style.scss'
import '@/scss/component.scss' // 复用组件样式
import 'material-icons/iconfont/material-icons.scss';
//import Snackbar from '@/components/snackbar.vue'
import vuePicturePreview from 'vue-picture-preview';

import VueLoadmore from 'vuejs-loadmore';

Vue.use(VueLoadmore);

Vue.mixin(globalMixins);
Vue.component('Previewer', vuePicturePreview);
Vue.config.productionTip = false;
Vue.use(VueCropper)
Vue.use(VuetifySnackbarQueue)
Vue.use(VueMeta);
Vue.use(snackbar, { vuetify, params: {} })
Vue.use(dialog, { vuetify, params: {} })
Vue.use(ElementUI);
Vue.component('Page', Page)
Vue.use(VueLazyLoad)

// 全局函数
Vue.prototype.$storage = storage;
Vue.prototype.$config = config;

// 拦截nprogress避免重复加载
import NProgress from 'nprogress'
Vue.config.productionTip = false

let requestQueue = []

const updateRequestQueue = (config, isResponse = false) => {
  const itemIndex = requestQueue.findIndex((item) => item.url === config.url && item.method === config.method)
  if (isResponse) {
    if (itemIndex !== -1) {
      // remove the request from the queue
      requestQueue.splice(itemIndex, 1)
    }
  } else {
    if (itemIndex === -1) {
      // add the request to the queue
      requestQueue.push({
        url: config.url,
        method: config.method,
      })
    }
  }

  if (requestQueue.length === 0) {
    setTimeout(() => {
      if (requestQueue.length === 0) {
        NProgress.done()
      }
    }, 200) // add a delay before ending the progress bar
  } else if (!NProgress.isStarted()) {
    NProgress.start()
  }
}

axios.interceptors.request.use(config => {
  updateRequestQueue(config)
  return config
}, error => {
  updateRequestQueue(error.config, true)
  return Promise.reject(error)
})

axios.interceptors.response.use(response => {
  updateRequestQueue(response.config, true)
  return response
}, error => {
  updateRequestQueue(error.config, true)
  return Promise.reject(error)
})

router.beforeEach((to, from, next) => {
  NProgress.start() // always start the progress bar on route change
  next()
})

router.afterEach(() => {
  if (requestQueue.length === 0) {
    setTimeout(() => {
      if (requestQueue.length === 0) {
        NProgress.done()
      }
    }, 200) // add a delay before ending the progress bar
  }
})

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')



 