
import {
    suggestion,
} from "@/api";
const state = {
    comment_send_data: {},
    save_components: {}
};
//修改数据
const mutations = {
    edit_comment_send_data(state, data) {
        state.comment_send_data = data;
    },
    edit_save_components(state, data) {
        state.save_components = data;
    },
};
//异步操作逻辑
const actions = {
    async suggestion({ commit }, data) {
        let result = await suggestion(data);
        if (result.data.code == 200) {
            return Promise.resolve(result.data);
        } else {
            return Promise.reject({
                code: result.data.code,
                info: new Error(result.data.msg)
            });
        }
    },
};
//计算属性
const getters = {
    comment_send_data(state) {
        return state.comment_send_data;
    },
    save_components(state) {
        return state.save_components;
    },
};

export default {
    state,
    mutations,
    actions,
    getters
}