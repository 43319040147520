import {
  reqUserLogin,
  reqUserRegister,
  reqUserForget,
  reqGetCode,
  get_User,
  exit,
  upload_Avatar,
  apply_change_Avatar,
  verify_Avatar,
  get_Reply_notice,
  get_notice,
  add_favorite,
  del_favorite,
  like,
  get_history,
  get_favorite,
  edit_user_name,
  email_verify_send,
  user_change_password,
  email_verify
} from "@/api";
import { setJwt, getJwt, removeJwt } from "@/utils/jwt";
import { storage } from "@/utils/tools";
//数据
const state = {
  jwt: getJwt(),
  captcha: null,
  reply_me_data: [],
  sys_notice_data: [],
  history_data: [],
  favorite_data: [],
  change_avatar_uuid: '',
  isLogin: false,
  user_info: null,
  user_avatar: "",
  local_user_info: storage.getStroage("local_user_info"),
};
//修改数据
const mutations = {
  jwt(state, jwt) {
    state.jwt = jwt;
  },
  edit_captcha(state, data) {
    state.captcha = data;
  },
  edit_user_info(state, data) {
    state.user_info = data;
  },
  edit_user_avatar(state, data) {
    state.user_avatar = data;
  },
  edit_change_avatar_uuid(state, data) {
    state.change_avatar_uuid = data;
  },
  edit_isLogin(state, data) {
    state.isLogin = data;
  },
  // 回复我的数据
  edit_reply_me_data(state, data) {
    state.reply_me_data = data;
  },
  edit_sys_notice_data(state, data) {
    state.sys_notice_data = data;
  },
  edit_history_data(state, data) {
    state.history_data = data;
  },
  edit_favorite_data(state, data) {
    state.favorite_data = data;
  },
};
//异步操作逻辑
const actions = {
  async UserLogin({ commit }, data) {
    let result = await reqUserLogin(data);
    if (result.data.code == 200) {
      commit("jwt", result.data.data.jwt);
      //写jwt密钥到本地
      setJwt(result.data.data.jwt);
    } else {
      return Promise.reject({
        code: result.data.code,
        info: new Error(result.data.msg)
      });
    }
  },
  async GetCode({ commit }, data) {
    let result = await reqGetCode(data);
    if (result.data) {
      // 由于传回图片无法直接使用，作特殊处理
      let url = window.URL.createObjectURL(new Blob([result.data], { type: 'image/png' }))
      commit("edit_captcha", url);
      return Promise.resolve(url);
    } else {
      return Promise.reject({
        code: 404,
        info: new Error('加载验证码失败')
      });
    }
  },
  async Register({ commit }, data) {
    let result = await reqUserRegister(data);
    if (result.data.code == 200) {
      return Promise.resolve(result.data);
    } else {
      return Promise.reject({
        code: result.data.code,
        info: new Error(result.data.msg)
      });
    }
  },
  async Forget({ commit }, data) {
    let result = await reqUserForget(data);
    if (result.data.code == 200) {
      return Promise.resolve(result.data);
    } else {
      return Promise.reject({
        code: result.data.code,
        info: new Error(result.data.msg)
      });
    }
  },
  //  获取用户信息
  async get_User({ commit }, data) {
    let result = await get_User(data);
    if (result.data.code == 200) {
      return Promise.resolve(result.data);
    } else {
      return Promise.reject({
        code: result.data.code,
        info: new Error(result.data.msg),
      });
    }
  },
  //  退出登录
  async exit({ commit }, data) {
    let result = await exit();
    if (result.data.code == 200) {
      // 清空vuex的用户信息
      commit("edit_user_info", null);
      // 传登录状态为未登录
      commit("edit_isLogin", false);
      // 清空本地存储的用户信息
      localStorage.setItem("local_user_info", null);
      // 删除jwt
      localStorage.removeItem("jwt");
      return Promise.resolve(result.data);
    } else {
      return Promise.reject({
        code: result.data.code,
        info: new Error(result.data.msg),
      });
    }
  },
  // 用户头像上传
  async upload_Avatar({ commit, state }, data) {
    let result = await upload_Avatar(data);
    if (result.data.code == 200) {
      return Promise.resolve(result.data);
    } else {
      return Promise.reject({
        code: result.data.code,
        info: new Error(result.data.msg),
      });
    }
  },
  async user_change_password({ commit, state }, data) {
    let result = await user_change_password(data);
    if (result.data.code == 200) {
      return Promise.resolve(result.data);
    } else {
      return Promise.reject({
        code: result.data.code,
        info: new Error(result.data.msg),
      });
    }
  },
  async email_verify({ commit, state }, data) {
    let result = await email_verify(data);
    if (result.data.code == 200) {
      return Promise.resolve(result.data);
    } else {
      return Promise.reject({
        code: result.data.code,
        info: new Error(result.data.msg),
      });
    }
  },
  async apply_change_Avatar({ commit, state }, data) {
    let result = await apply_change_Avatar(data);
    if (result.data.code == 200) {
      commit("edit_change_avatar_uuid", result.data);
      return Promise.resolve(result.data);
    } else {
      commit("edit_change_avatar_uuid", false);
      return Promise.reject({
        code: result.data.code,
        info: new Error(result.data.msg),
      });
    }
  },
  async verify_Avatar({ commit, state }, data) {
    let result = await verify_Avatar(data);
    if (result.data.code == 200) {
      return Promise.resolve(result.data);
    } else {
      return Promise.reject({
        code: result.data.code,
        info: new Error(result.data.msg),
      });
    }
  },
  async email_verify_send({ commit, state }, data) {
    let result = await email_verify_send(data);
    if (result.data.code == 200) {
      return Promise.resolve(result.data);
    } else {
      return Promise.reject({
        code: result.data.code,
        info: new Error(result.data.msg),
      });
    }
  },
  // 获取回复我的评论
  async get_Reply_notice({ commit }, data) {
    let result = await get_Reply_notice(data);
    if (result.data.code == 200) {
      commit("edit_reply_me_data", result.data);
      return Promise.resolve(result.data);
    } else if (result.data.code == 201) {
      return Promise.resolve(result);
    } else {
      return Promise.reject({
        code: result.data.code,
        info: new Error(result.data.msg),
      });
    }
  },
  //  获取系统消息
  async get_notice({ commit }, data) {
    let result = await get_notice(data);
    if (result.data.code == 200) {
      commit("edit_sys_notice_data", result.data.data);
      return Promise.resolve(result.data);
    } else if (result.data.code == 201) {
      return Promise.resolve(result);
    } else {
      return Promise.reject({
        code: result.data.code,
        info: new Error(result.data.msg),
      });
    }
  },
  //  添加收藏
  async add_favorite({ commit }, data) {
    let result = await add_favorite(data);
    if (result.data.code == 200) {
      return Promise.resolve(result.data);
    } else {
      return Promise.reject({
        code: result.data.code,
        info: new Error(result.data.msg),
      });
    }
  },
  //  删除收藏
  async del_favorite({ commit }, data) {
    let result = await del_favorite(data);
    if (result.data.code == 200) {
      return Promise.resolve(result.data);
    } else {
      return Promise.reject({
        code: result.data.code,
        info: new Error(result.data.msg),
      });
    }
  },
  //  获取收藏内容
  async get_favorite({ commit }, data) {
    let result = await get_favorite(data);
    if (result.data.code == 200) {
      commit("edit_favorite_data", result.data);
      return Promise.resolve(result.data);
    } else {
      return Promise.reject({
        code: result.data.code,
        info: new Error(result.data.msg),
      });
    }
  },
  //  获取历史记录
  async get_history({ commit }, data) {
    let result = await get_history(data);
    if (result.data.code == 200) {
      commit("edit_history_data", result.data);
      return Promise.resolve(result.data);
    } else {
      return Promise.reject({
        code: result.data.code,
        info: new Error(result.data.msg),
      });
    }
  },

  //  点赞点踩
  async like({ commit }, data) {
    let result = await like(data);
    if ([200, 400].includes(parseInt(result.data.code))) {
      return Promise.resolve(result.data);
    } else {
      return Promise.reject({
        code: result.data.code,
        info: new Error(result.data.msg),
      });
    }
  },
  //  修改用户名
  async edit_user_name({ commit }, data) {
    let result = await edit_user_name(data);
    if (result.data.code == 200) {
      return Promise.resolve(result.data);
    } else {
      return Promise.reject({
        code: result.data.code,
        info: new Error(result.data.msg),
      });
    }
  },
};
//计算属性
const getters = {
  user_info(state) {
    return state.user_info;
  },
  userAvatar(state) {
    return state.user_avatar;
  },
  isLogin(state) {
    return state.isLogin;
  },
  captcha(state) {
    return state.captcha;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
