<template>
  <div>
    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-size="1" layout="prev, pager, next, jumper" :total="view_total">
    </el-pagination>
  </div>
</template>

<script>
export default {
  methods: {
    handleSizeChange(val) {
    },
    handleCurrentChange(val) {
    }
  },
  data() {
    return {
      currentPage1: 1,
    };
  },
  props:["view_total"],
}
</script>

<style>
@media (max-width: 767px){
  .el-pagination li {
    display: none;
}
}

.v-application ul, .v-application ol {
    padding-left: 0px;
}

menu, ol, ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

</style>
 
