<template>
  <v-navigation-drawer v-model="getUserMenuDrawer" class="navigation" :touchless="true" temporary fixed>
    <!-- color="#f2f2f2" -->
    <div class="popbox-header">
      <div class="avatar" v-lazy-container="{ selector: 'img' }">
        <img :data-src="userAvatar" :data-error="userErrImg" alt="" />
      </div>
      <div class="user-info" v-if="user_info != null">
        <div class="user-name">{{ user_info.name }}</div>
        <div class="exp">
          <div class="exp-info">
            <div class="level mgr-10">等级 {{ user_info.level.level_name }}</div>
            <div class="now-exp">
              <span class="d-p1">{{ user_info.level.exp }}</span> /
              {{ user_info.level.next_exp }}
            </div>
          </div>
          <v-progress-linear v-model="expLinear" value="60" height="6" color="orange">
          </v-progress-linear>
        </div>
      </div>
    </div>
    <v-divider></v-divider>
    <v-list class="navigation-list" nav dense>
      <v-list-item link :to="{ name: 'History' }">
        <v-list-item-icon>
          <v-icon>mdi-history</v-icon>
        </v-list-item-icon>
        <v-list-item-title>历史记录</v-list-item-title>
      </v-list-item>
      <v-list-item link :to="{ name: 'Favorite' }">
        <v-list-item-icon>
          <v-icon>mdi-heart-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title>我的订阅</v-list-item-title>
      </v-list-item>
      <v-list-item link :to="{ name: 'Setting' }">
        <v-list-item-icon>
          <v-icon>mdi-account</v-icon>
        </v-list-item-icon>
        <v-list-item-title>个人中心</v-list-item-title>
      </v-list-item>
    </v-list>
    <v-divider></v-divider>
    <v-list class="navigation-list" nav dense>
      <v-list-item link :to="{ name: 'ReplyMe' }">
        <v-list-item-icon>
          <v-icon>mdi-email-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title>回复我的</v-list-item-title>
        <div class="newMessage" v-if="user_info != null && user_info.reply_notice != 0"></div>
      </v-list-item>
      <v-list-item link :to="{ name: 'SysNotice' }">
        <v-list-item-icon>
          <v-icon>mdi-bell</v-icon>
        </v-list-item-icon>
        <v-list-item-title>系统通知</v-list-item-title>

        <div class="newMessage" v-if="user_info != null && user_info.system_notice != 0"></div>
      </v-list-item>
    </v-list>
    <v-list class="navigation-list" style="margin-top: auto" nav dense>
      <v-list-item @click="
        exit();
      getUserMenuDrawer = false;
      ">
        <v-list-item-icon>
          <v-icon>mdi-exit-to-app</v-icon>
        </v-list-item-icon>
        <v-list-item-title>退出登录</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { exit } from "@/api/request/user";
import bus from "@/store/bus";
export default {
  name: "UserMenuDrawer",
  mixins: [],
  props: ["userMenuDrawer"],
  data() {
    return {
      getUserMenuDrawer: null,
    };
  },
  computed: {
    user_info() {
      return this.$store.getters.user_info;
    },
    expLinear: {
      get: function () {
        return this.user_info.level.exp / (this.user_info.level.next_exp / 100);
      },
      set: function () { },
    },
  },
  methods: {
    exit() {
      return exit.call(this);
    },
  },
  created() {
    bus.$on("getUserMenuDrawer", (data) => {
      this.getUserMenuDrawer = data;
    });
  },
  mounted() { },
  beforeDestroy() {
    bus.$off("getUserMenuDrawer");
  },
  watch: {
    userMenuDrawer(newVal) {
      this.getUserMenuDrawer = newVal;
    },
    getUserMenuDrawer(newVal) {
      this.$emit("getUserMenuDrawer", newVal);
    },
  },
};
</script>
<style lang="scss" scoped>
.exp {
  padding: 0 20px 0 0;

  .v-progress-linear {
    margin-bottom: 4px;
  }

  .exp-info {
    display: flex;
    padding: 4px 0;

    .level {
      font-size: 0.7rem;
    }

    .now-exp {
      font-size: 0.7rem;
      margin-left: auto;
    }
  }

  ::v-deep .el-progress {
    .el-progress-bar {
      padding: 0;
      margin-right: 0;
    }
  }
}

// .v-divider {
//     border-color: #d3d3d3 !important;
// }

.popbox-header {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 10px 0;
  background-color: transparent !important;
  padding: 1.8rem 1rem 1.2rem 1rem;

  .avatar {
    width: 60px;
    height: 60px;
    cursor: pointer;
    margin: 0;
    border-radius: 50%;
    border: 3px solid #fff;
    background-color: transparent;
    overflow: hidden;

    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  .user-info {
    width: 100%;
    text-align: center;

    .user-name {
      color: #e1e1e1;
      padding: 4px 0;
      font-size: 1.1rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .n-vip {
      width: 44px;
      margin: 0 auto;
      padding: 0 2px;
      font-size: 0.7rem;
      font-weight: 600;
      letter-spacing: 1px;
      border-radius: 11px;
      background-color: #e45a81;
      color: white;
    }

    .exp {
      padding: 0 20px;

      .exp-info {
        display: flex;
        padding: 4px 0;
        padding-top: 12px;
        color: #e1e1e1;

        .level {
          font-size: 0.8rem;
        }

        .now-exp {
          font-size: 0.8rem;
          margin-left: auto;
        }
      }

      ::v-deep .el-progress {
        .el-progress-bar {
          padding: 0;
          margin-right: 0;
        }
      }
    }
  }
}

.newMessage {
  padding: 4px;
  border-radius: 20px;
  background-color: #d84141;
}

.navigation {
  background-color: rgba($color: #363636, $alpha: 0.5) !important;
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px);
  top: 113px !important;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);

  @media (max-width: 768px) {
    top: 0 !important;
  }

  ::v-deep .v-navigation-drawer__content {
    display: flex;
    flex-direction: column;

    .info-box {
      display: flex;
      flex-direction: row;

      .info-avatar {
        flex: 0;
        margin-right: 4px;

        .v-avatar {
          margin-right: 0;
          overflow: inherit;
        }
      }

      .info-title {
        padding: 0 6px;
        flex: auto;

        .v-list-item__content {
          display: flex;

          .v-list-item__title {
            display: flex;
            line-height: normal;
            margin-bottom: 8px;
            font-size: 0.9rem;
            font-weight: 600;
          }

          .v-list-item__subtitle {}
        }
      }
    }

    .v-list {
      border: 0;
      padding: 14px 8px;
    }

    .navigation-list {
      .v-list-item--active {
        color: #e45a81;
      }

      .v-list-item {
        .v-list-item__icon {
          i {
            // color: #676767 !important;
          }
        }

        .v-list-item__title {
          font-size: 0.9rem;
          // color: #676767;
        }
      }
    }
  }
}
</style>
