<template>
  <div class="search-box" :class="{ 'search-box--active': searchPanelShow }">
    <div class="search-input-box d-flex ai-c">
      <div class="search-back" v-show="show_searchBack" @click="searchBack">
        <v-icon>mdi-chevron-left</v-icon>
      </div>
      <form v-show="show_searchTextInput" class="search-box__form h-full" ref="search-box__form"
        @submit.prevent="searchCheck()">
        <input type="text" class="search-box__input h-full" :placeholder="placeholder" @click="clickSearchInput"
          ref="searchTextInput" v-model="keyword" />
        <v-btn v-show="keyword && keyword.length > 0" class="search-box__clear" max-width="20" max-height="20" icon
          @click="clearKeyword">
          <v-icon size="12">mdi-close-circle</v-icon>
        </v-btn>
        <v-btn class="search-box__submit" h icon @click="searchCheck()">
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </form>
    </div>
    <div class="search-panel-box" v-show="searchPanelShow">
      <div class="search-panel">
        <div class="history" ref="history">
          <div class="title-box">
            <div class="d-title">历史搜索</div>
            <div class="d-clear" @click="historiesClear">清空</div>
          </div>
          <div class="histories-wrap">
            <div class="histories" ref="histories">
              <div class="history-item" v-for="item in searchHistories" :key="item.index" @click="searchCheck(item)">
                <div class="history-text">{{ item }}</div>
              </div>
            </div>
          </div>
          <div class="history-fold-wrap" v-show="foldMoreIsShow" @click="historiesFold">
            <div class="fold-text" ref="foldText">展开更多</div>
            <v-icon>{{
              searchHistoriesIsFold ? "mdi-chevron-down" : "mdi-chevron-up"
            }}</v-icon>
          </div>
        </div>
        <!-- <div class="trending">
          <div class="title-box">
            <div class="d-title">热门搜索</div>
          </div>
          <!====>
          <div class="trendings-double">
            <div class="trendings-col">
              <div class="trending-item" tabindex="0" v-for="(item, index) in hotWordData[0]" :key="index"
                @click="searchCheck(item)">
                <div class="trendings-rank search-rank-top">
                  {{ index + 1 }}
                </div>
                <div class="trending-text">{{ item }}</div>
              </div>
            </div>
            <div class="trendings-col">
              <div class="trending-item" tabindex="0" v-for="(item, index) in hotWordData[1]" :key="index"
                @click="searchCheck(item)">
                <div class="trendings-rank search-rank-top">
                  {{ index + 6 }}
                </div>
                <div class="trending-text">{{ item }}</div>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { get_Hot_Word } from "@/api/request/search";
import bus from "@/store/bus";
export default {
  name: "header_search",
  mixins: [],
  props: ["searchBoxShow"],
  data() {
    return {
      loading: true,
      searchPanelShow: false,
      show_searchTextInput: true, // 是否显示搜索框 用于移动端
      show_searchBack: false, // 是否显示返回按钮 用于移动端
      searchBoxState: "",
      searchHistories: [],
      searchHistoriesIsFold: true,
      foldMoreIsShow: true,
      keyword: "",
      hotWordData: [],
      hotWordTime: 0,
      items: [],
      placeholder: '搜索标题或内容相关标签'
    };
  },
  methods: {
    searchBack() {
      this.searchBoxState = "inputDefult";
    },
    async clickSearchInput() {
      this.searchBoxState = "inputFocus";
      // 从本地存储获取热门搜索信息
      let hotWordData = this.$storage.getStroage("hotWordData");
      if (hotWordData == null) {
        await this.setHotWordData();
      } else {
        hotWordData = JSON.parse(hotWordData);
        if (hotWordData.data.length > 1) {
          let newData = new Date().getTime();
          let oldData = hotWordData.time;
          let DiffDay = this.getDiffDay(newData, oldData);
          // 如果上一次请求时间与当前时间相差大于一天，则重新请求热门搜索信息
          if (DiffDay > 1) {
            await this.setHotWordData();
          } else {
            this.hotWordData = hotWordData.data;
          }
        } else {
          await this.setHotWordData();
        }
      }
    },
    clearKeyword() {
      this.keyword = "";
    },
    /**
     *  请求热搜信息并写入data
     */
    async setHotWordData() {
      await this.get_Hot_Word(this.category);
      let hotWord = this.$store.state.data.hot_word;
      if (hotWord) {
        hotWord = Object.keys(hotWord.data.keywords);
      } else {
        hotWord = {};
      }
      hotWord;
      let temp1 = [],
        temp2 = [];
      let hotWordLength = hotWord.length;
      for (let i = 0; i < hotWordLength; i++) {
        if (i < 5) {
          temp1.push(hotWord[i]);
        } else {
          temp2.push(hotWord[i]);
        }
      }
      this.hotWordData = [temp1, temp2];
      let oldTime = new Date().getTime();
      let hotWordLocalData = {
        time: oldTime,
        data: this.hotWordData,
      };
      this.$storage.setStroage("hotWordData", JSON.stringify(hotWordLocalData));
    },
    async searchCheck(params) {
      // 判断搜索关键词来自输入框（this.keyword）还是热搜和历史搜索（params）

      let keyword = params ? params : this.keyword;

      if (!keyword || keyword.trim() === "") {
        return false;
      } else {
        this.keyword = keyword;
        if (this.foldMoreIsShow == false) {
          this.foldMoreIsShow = true;
        }
        // 将输入的内容写入历史搜索
        if (this.searchHistories.indexOf(keyword) == -1) {
          this.searchHistories.unshift(keyword);
        }
        this.searchBoxState = "inputItemFocus";
        //跳转到搜索页面
        this.$router
          .push({ path: "/search", query: { keyword: keyword, page: 1 } })
          .catch((err) => err);

        this.searchBoxState = "inputDefult";
      }
    },

    getSearchHistoryBylocal() {
      let searchHistories = this.$storage.getStroage("searchHistories");
      if (searchHistories == null) {
        this.$storage.setStroage("searchHistories", "");
        searchHistories = this.$storage.getStroage("searchHistories");
      }
      if (searchHistories.length > 0) {
        this.searchHistories = searchHistories;
      } else {
        this.foldMoreIsShow = false;
        this.searchHistories = [];
      }
    },
    historiesFold() {
      if (this.searchHistoriesIsFold) {
        this.$refs.foldText.innerText = "收起";
        document.querySelector(".histories-wrap").style.maxHeight = 172 + "px";
      } else {
        this.$refs.foldText.innerText = "展开更多";
        document.querySelector(".histories-wrap").style.maxHeight = 86 + "px";
      }
      this.searchHistoriesIsFold = !this.searchHistoriesIsFold;
    },
    historiesClear() {
      this.searchHistories = [];
      this.$storage.setStroage("searchHistories", "");
      this.foldMoreIsShow = false;
    },
    handleInputDefult() {
      let self = this;
      self.searchBoxState = "inputDefult";
    },
    // 工具函数
    getDiffDay(date_1, date_2) {
      // 计算两个日期之间的差值
      let totalDays, diffDate;
      diffDate = Math.abs(date_1 - date_2); // 取相差毫秒数的绝对值
      totalDays = (diffDate / (1000 * 3600 * 24)).toFixed(3); // 四舍五入不取证
      return totalDays; // 相差的天数
    },
    async get_Hot_Word(type) {
      const res = await get_Hot_Word.call(this, type);
    },
  },

  created() {
    let query = new URLSearchParams(window.location.search);
    let [keyword, cnKeyword] = [query.get('keyword'), query.get('cnKeyword')]
    this.keyword = cnKeyword ? cnKeyword : keyword;
    this.getSearchHistoryBylocal();

    bus.$on('changeKeyword', (data) => {
      this.keyword = data;
    })
  },
  mounted() {
    // 点击任意位置关闭搜索面板
    let that = this;
    document.addEventListener("click", this.handleInputDefult, false);
    let searchPanel = document.querySelector(".nav-center");
    searchPanel.addEventListener("click", function (e) {
      e.stopPropagation();
    });
    this.searchBoxState = "inputDefult";
  },
  beforeDestroy() {
    let that = this;
    let searchPanel = document.querySelector(".nav-center");
    if (searchPanel) {
      searchPanel.removeEventListener("click", function (e) {
        e.stopPropagation();
      });
    }
    document.removeEventListener("click", this.handleInputDefult, false);
  },
  watch: {
    searchHistories(newVal, oldVal) {
      this.$storage.setStroage("searchHistories", newVal);
    },
    searchBoxShow(newVal, oldVal) {
      if (newVal) this.clickSearchInput();
    },
    searchBoxState(newVal) {
      this.$emit("searchBoxState", newVal);
      switch (newVal) {
        case "inputDefult":
          if (window.innerWidth > 768) {
            this.show_searchBack = false;
            this.searchPanelShow = false;
            this.show_searchTextInput = true;
          } else {
            this.show_searchBack = false;
            this.searchPanelShow = false;
            this.show_searchTextInput = false;
            document.querySelector("html").style.overflow = "";
          }
          break;
        case "inputFocus":
          if (window.innerWidth > 768) {
            this.show_searchBack = false;
            this.searchPanelShow = true;
            this.show_searchTextInput = true;
          } else {
            this.show_searchBack = true;
            this.searchPanelShow = true;
            this.show_searchTextInput = true;
            document.querySelector("html").style.overflow = "hidden";
          }
          break;
        case "inputItemFocus":
          if (window.innerWidth > 768) {
            this.show_searchBack = false;
            this.searchPanelShow = false;
            this.show_searchTextInput = true;
          } else {
            this.show_searchBack = true;
            this.searchPanelShow = false;
            this.show_searchTextInput = true;
            document.querySelector("html").style.overflow = "";
          }
          break;
        default:
          console.error("Invalid searchBoxState value");
      }
    },
    screenWidth(newWidth) {
      switch (this.searchBoxState) {
        case "inputDefult":
          if (window.innerWidth > 768) {
            this.show_searchBack = false;
            this.searchPanelShow = false;
            this.show_searchTextInput = true;
          } else {
            this.show_searchBack = false;
            this.searchPanelShow = false;
            this.show_searchTextInput = false;
          }
          break;
        case "inputFocus":
          if (window.innerWidth > 768) {
            this.show_searchBack = false;
            this.searchPanelShow = true;
            this.show_searchTextInput = true;
          } else {
            this.show_searchBack = true;
            this.searchPanelShow = true;
            this.show_searchTextInput = true;
          }
          break;
        case "inputItemFocus":
          if (window.innerWidth > 768) {
            this.show_searchBack = false;
            this.searchPanelShow = false;
            this.show_searchTextInput = true;
          } else {
            this.show_searchBack = true;
            this.searchPanelShow = false;
            this.show_searchTextInput = true;
          }
          break;
        default:
          console.error("Invalid searchBoxState value");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@media (min-width: 768px) {
  .search-box--active {
    background-color: #5c5c5c;
    padding: 6px 0;
  }
}

.search-box {
  display: flex;
  border-radius: 6px;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 768px) {}

  .search-box__form {
    position: relative;
    width: 100%;
    padding: 0 8px;
    margin: 0 auto;

    .search-box__input {
      height: 38px;
      padding: 2px 48px 2px 10px;
      color: #a7a7a7;
      width: 100%;
      background-color: #464646;
      border-radius: 6px;

      @media (max-width: 768px) {
        height: 38px;
      }
    }

    .search-box__clear {
      position: absolute;
      right: 60px;
      top: 50%;
      transform: translate(0, -50%);

      &::before {
        content: none;
      }
    }

    .search-box__submit {
      position: absolute;
      right: 14px;
      top: 50%;
      transform: translate(0, -50%);
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      padding: 0;
      width: 32px !important;
      height: 32px !important;
      border: none;
      border-radius: 6px;
      color: var(--text1);
      line-height: 32px;
      cursor: pointer;
      transition: background-color 0.3s;
    }
  }

  .search-panel-box {
    width: 100%;
    max-height: 612px;
    padding: 16px 14px;

    // 搜索手机端
    @media (max-width: 768px) {
      position: absolute;
      left: 0;
      top: 56px;
      width: 100%;
      height: calc(100vh - 56px);
      overflow: scroll;
      background-color: #515151;
      max-height: none;
    }

    .search-panel {
      .history {
        .title-box {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 8px;
          padding: 0;

          .d-title {
            font-size: 1rem;
            color: #fff;
          }

          .d-clear {
            font-size: 0.8rem;
            color: #fff;
            cursor: pointer;
          }
        }

        // 变量

        .histories-wrap {
          overflow: hidden;
          max-height: 86px;

          .histories {
            display: flex;
            flex-wrap: wrap;
            margin-top: 12px;

            .history-item {
              position: relative;
              box-sizing: border-box;
              height: 30px;
              padding: 7px 10px 8px;
              font-size: 12px;
              line-height: 15px;
              background-color: #707070;
              border-radius: 4px;
              margin-right: 10px;
              margin-bottom: 10px;
              cursor: pointer;

              &:hover {
                background-color: #919191;
              }

              .history-text {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 96px;
              }
            }
          }
        }

        .history-fold-wrap {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 64px;
          margin: 14px auto;

          .fold-text {
            font-size: 12px;
            line-height: 15px;
            height: 15px;
            color: var(--text3);
            cursor: pointer;

            &:hover {
              color: var(--text1);
            }
          }

          .v-icon {
            width: 12px;
            height: 12px;
            cursor: pointer;
            margin-left: 2px;
          }
        }
      }

      .trending {
        .title-box {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 8px;
          padding: 0;

          .d-title {
            font-size: 1rem;
            color: #fff;
          }
        }
      }

      .trendings-double {
        display: flex;

        .trendings-col {
          flex: 1;
          overflow: hidden;

          &:first-child {
            margin-right: 10px;
          }

          .trending-item {
            box-sizing: border-box;
            height: 38px;
            display: flex;
            align-items: center;
            cursor: pointer;
            padding-left: 2px;

            .trendings-rank {
              min-width: 18px;
              max-width: 18px;
              height: 17px;
              line-height: 17px;
              text-align: left;
              font-size: 14px;
              margin-right: 10px;
            }

            .search-rank-top {
              color: #b7b7b7;
            }

            .trending-text {
              font-size: 14px;
              line-height: 17px;
              height: 17px;
              margin-right: 6px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              letter-spacing: 0;
              max-width: 11rem;
            }
          }
        }
      }
    }
  }
}

.search-back {
  display: none;

  @media (max-width: 768px) {
    display: block;
  }
}
</style>
