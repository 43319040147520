<template>
  <v-footer class="footer" padless absolute app>
    <div class="footer-main">
      <div class="footer-container-box">
        <v-row justify="center" no-gutters>
          <v-col col="16">
            <div class="footer-container d-flex">
              <div class="footer-btn-box">
                <v-avatar size="32">
                  <img src="/favicon.png"/></v-avatar>
                <!-- 数据来自同目录下的friendLink.json -->
                <v-tooltip top color="error">
                  <template v-slot:activator="{ on, attrs }">
                    <a href="javascript:;" v-bind="attrs" v-on="on">
                      <span>App下载</span>
                    </a>
                  </template>
                  <span>暂未上线</span>
                </v-tooltip>

                <a href="javascript:;" @click="regulation = true">
                  <span>用户须知</span>
                </a>
                <a href="javascript:;" @click="$emit('update:value', $event)">
                  <span>重要公告</span>
                </a>
                <a class="friendlink" href="javascript:;" @click="clickFriendLink" v-click-outside="{
                  handler: outsideClickFriendLink,
                  include: elFriendlink,
                }">
                  <v-icon style="margin: 1px 4px 0 0" size="16">mdi-link-variant</v-icon>
                  <span>友情链接</span>
                  <v-icon class="friend-icondown" size="24" color="#ff6297">mdi-menu-down</v-icon>
                </a>
              </div>
              <transition name="fade">
                <v-divider style="margin-top: 10px" v-show="isShowFriendLink"></v-divider>
              </transition>
              <transition name="fade">
                <div class="friendlink-box" v-show="isShowFriendLink">
                  <a v-for="item in friendLink" :href="item.url" transition="slide-x-transition" target="_blank">
                    <span>{{ item.title }}</span>
                  </a>
                </div>
              </transition>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
    <Regulation :value="regulation" @update:value="regulation = $event"></Regulation>
    <v-dialog v-model="downLoadApp">
      <v-card>
        <v-card-title class="">App</v-card-title>
        <v-card-text>暂未上线</v-card-text>
      </v-card>
    </v-dialog>
  </v-footer>
</template>
<script>
import bus from "@/store/bus";
import axios from "axios";
import Regulation from "@/components/ui/features/Regulation";
export default {
  name: "Footer",
  mixins: [],
  props: ["value"],
  data() {
    return {
      regulation: false, // 用户须知
      downLoadApp: false, // app
      isShowFriendLink: false,
      donwLoadApp: false,
      friendLink: []
    };
  },
  components: {
    Regulation,
  },
  computed: {
  },
  methods: {
    init() {
      axios
        .get("/doc/friendLink.json")
        .then((response) => {
          // 处理响应数据
          this.friendLink = response.data;
        })
        .catch((error) => {
          // 处理错误
          console.error(error);
        });
    },
    clickFriendLink() {
      if (this.isShowFriendLink == true) {
        this.isShowFriendLink = false
      } else {
        this.isShowFriendLink = true;
      }

      this.$nextTick(() => {

        let vmain = document.querySelector(".v-main");
        let currentPadding = parseInt(vmain.style.paddingBottom); // 将字符串转换为整数
        let friendlink_box =
          document.querySelector(".friendlink-box").clientHeight;
        let newPadding; // 增加上内边距值
        if (this.isShowFriendLink == true) {
          newPadding = currentPadding + friendlink_box; // 增加上内边距值
        } else {
          newPadding = currentPadding - friendlink_box;
        }

        vmain.style.paddingBottom = newPadding + "px"; // 将新的上内边距值应用到元素
        window.scrollTo(0, document.body.scrollHeight);
      });
    },
    // 填不了参数，创建一个新的处理函数
    outsideClickFriendLink() {
      if (this.isShowFriendLink == false) {
        return;
      }
      let friendlink_box =
        document.querySelector(".friendlink-box").clientHeight;
      this.isShowFriendLink = false;
      this.$nextTick(() => {
        let vmain = document.querySelector(".v-main");
        let currentPadding = parseInt(vmain.style.paddingBottom); // 将字符串转换为整数
        let newPadding = currentPadding - friendlink_box; // 增加上内边距值
        vmain.style.paddingBottom = newPadding + "px"; // 将新的上内边距值应用到元素
        window.scrollTo(0, document.body.scrollHeight);
      });
    },
    closeAnnouncement() {
      this.announcement = false;
      let storage = this.$storage.getUserOptions();
      storage.noAnnouncement = true;
      this.$storage.setStroage("userOptions", storage);
    },
    elFriendlink() {
      return [document.querySelector(".friendlink-box")];
    },
  },
  created() {
    this.init();
    let storage = this.$storage.getUserOptions();
    if (storage.noAnnouncement) {
      this.announcement = false;
    } else {
      this.announcement = true;
    }
  },
  mounted() { },
  destroyed() { },
  watch: {},
};
</script>
<style lang="scss">
.footer {
  border-radius: 0 !important;
  padding-top: 30px !important;
  border: 0 !important;
  box-shadow: none !important;
  background-color: inherit !important;
  transition: height 0.5s ease;

  .footer-main {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    max-width: 100%;
    transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    padding: 0 !important;
    background-color: #424242;
    border-color: rgba(255, 255, 255, 0.1) rgba(255, 255, 255, 0.07) rgba(255, 255, 255, 0.04);
    border-radius: 0;
    border-style: solid;
    border-width: 1px;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2),
      0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);

    .footer-container-box {
      padding: 0 16px;
      margin: 0 auto;
      width: 100%;

      @media (max-width: 768px) {
        padding: 8px 12px;
      }

      .row {
        .col {
          .footer-container {
            display: flex;
            flex-direction: column;
            padding: 0.6rem 0;

            @media (max-width: 768px) {
              padding: 0;
            }

            .footer-btn-box {
              display: flex;
              flex-wrap: wrap;
              align-items: center;

              .v-avatar {
                margin-right: 24px;

                @media (max-width: 360px) {
                  display: none;
                }
              }

              a {
                display: flex;
                align-items: center;
                margin: 4px 12px 4px 0;

                @media (max-width: 380px) {
                  margin: 4px 6px 4px 0;
                }

                .v-icon {
                  margin-left: 4px;
                  color: #a1a1a1;
                }

                span {
                  font-size: 0.8rem;
                  color: #a1a1a1;
                }
              }

              .friendlink {
                span {}

                .friend-icondown {
                  animation: floating 1s ease-in-out infinite;
                  margin-left: 0px;
                }
              }


            }

            .friendlink-box {
              display: flex;
              flex-direction: row;
              padding-top: 10px;
              flex-wrap: wrap;
              align-items: center;

              a {
                margin: 4px 6px 4px 0;
                color: #a1a1a1;
              }
            }
          }
        }
      }
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s; // 定义过渡动画的属性和时长
}

.fade-enter,
.fade-leave-to {
  opacity: 0; // 定义初始状态和结束状态
}

@keyframes floating {
  0% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(0, 4px);
  }

  100% {
    transform: translate(0, 0);
  }
}
</style>
