/**
 * @function 时间戳转年月日
 */
export function timestampToTime(timestamp) {
  let date = new Date(timestamp * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
  let Y = date.getFullYear() + "-";
  let M =
    (date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1) + "-";
  let D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
  let h = date.getHours() + ":";
  let m = date.getMinutes() + ":";
  let s = date.getSeconds();
  return Y + M + D;
}

/**
 * @function 禁止用户行为
 */
export function disableUserControl() {
  //取消选中并且取消右键操作
  document.body.onselectstart = document.body.oncontextmenu = function () {
    return false; // 取消浏览器默认操作
  };
  window.οncοntextmenu = function (e) {
    return false;
  };
  // 禁止F12等
  window.onkeydown = function () {
    if (window.event && window.event.keyCode == 123) {
      return false;
    }
    if (window.event && window.event.keyCode == 17) {
      return false;
    }
    if (window.event && window.event.keyCode == 16) {
      return false;
    }
  };
}
/**
 * @function 取消禁止用户行为
 */
export function clearDisableUserControl() {
  document.body.onselectstart = null;
  document.body.oncontextmenu = null;
  window.oncontextmenu = null;
  window.onkeydown = null;
}

/**
 * @function 将一个数组根据sortBy排序
 */
export function toSort(dataArray, isAscending, sortBy) {
  if (this[isAscending]) {
    dataArray.map((item) => {
      item.sort((a, b) => {
        return a[sortBy] - b[sortBy];
      });
    });
  } else {
    dataArray.map((item) => {
      item.sort((a, b) => {
        return b[sortBy] - a[sortBy];
      });
    });
  }
  this[isAscending] = !this[isAscending];
}

export function recordBtnText(category) {
  let text;
  let icon;
  switch (category) {
    case "video":
      text = "播放";
      break;
    case "comic":
      text = "阅读";
      break;
    case "novel":
      text = "阅读";
      break;
    case "sound":
      text = "播放";
      break;
  }
  switch (category) {
    case "video":
      icon = "mdi-play";
      break;
    case "comic":
      icon = "mdi-book-open-page-variant-outline";
      break;
    case "novel":
      icon = "mdi-book-open-page-variant-outline";
      break;
    case "sound":
      icon = "mdi-play";
      break;
  }
  return {
    text,
    icon,
  };
}

export function waitForArrayChange(array) {
  return new Promise((resolve) => {
    const initialValue = JSON.stringify(array); // 初始数组的序列化表示

    const checkArrayChange = () => {
      const currentValue = JSON.stringify(array); // 当前数组的序列化表示
      if (currentValue !== initialValue) {
        resolve();
      } else {
        setTimeout(checkArrayChange, 100); // 100 毫秒后再次检查
      }
    };

    checkArrayChange(); // 开始检查数组变化
  });
}

export function waitForElement(selector, callback) {
  const observer = new MutationObserver((mutationsList, observer) => {
    const element = document.querySelector(selector);
    if (element) {
      observer.disconnect(); // 停止观察
      callback(element); // 执行回调函数
    }
  });

  observer.observe(document.documentElement, { childList: true, subtree: true });
}

export function timeAgo(timestamp, space) {
  const minute = 60;
  const hour = 60 * minute;
  const day = 24 * hour;
  const week = 7 * day;
  const month = 30 * day;

  const now = Math.floor(new Date().getTime() / 1000);
  const diff = now - timestamp;

  if (diff < minute) {
    return "刚刚";
  } else if (diff < hour) {
    return `${Math.floor(diff / minute)}${space ? " 分钟前" : "分钟前"}`;
  } else if (diff < day) {
    return `${Math.floor(diff / hour)}${space ? " 小时前" : "小时前"}`;
  } else if (diff < week) {
    return `${Math.floor(diff / day)}${space ? " 天前" : "天前"}`;
  } else if (diff < month) {
    return `${Math.floor(diff / week)}${space ? " 周前" : "周前"}`;
  } else {
    return `${Math.floor(diff / month)}${space ? " 月前" : "月前"}`;
  }
}
export function commentTimeAgo(time) {
  const targetDate = new Date(time);
  const now = new Date();
  const diff = now - targetDate;
  const seconds = Math.floor(diff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  let message = "";
  if (days > 0) {
    message = `${days}天前`;
  } else if (hours > 0) {
    message = `${hours}小时前`;
  } else if (minutes > 0) {
    message = `${minutes}分钟前`;
  } else {
    message = `${seconds}秒前`;
  }
  return message;
}
export function setObjectInArray(array, resource_id, chapter_id, extraData) {
  if (Array.isArray(array) && array.length !== 0) {
    const object = array.find((item) => item.resource_id === resource_id);

    if (object) {
      const updatedObject = { ...object, ...extraData };
      updatedObject.chapter_id = chapter_id;

      const index = array.indexOf(object);
      array[index] = updatedObject;
      return array;
    }
  }
  return null;
}
export function findObjectInArray(array, id) {
  if (Array.isArray(array) && array.length !== 0) {
    return array.find((item) => item.resource_id === id) || null;
  }
  return null;
}

// 
/**
 * @function 滚动到指定元素
 * @params 1.元素 2.滚动方式 3.Y轴偏移量
 */
export function scrollToElement(element, behavior, offset) {
  const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
  const offsetPosition = elementPosition + offset;
  window.scrollTo({
    top: offsetPosition,
    behavior: behavior
  });
}

// 节流
export function _throttle(fn, delay = 5000) {
  let timer = null;
  let time = true;
  return function () {
    if (time) {
      fn.apply(this, arguments);
      time = false;
      timer = setTimeout(() => {
        time = true;
      }, delay);
    }
  };
}
// localStorage封装
export let storage = {
  // 添加
  setStroage(key, value) {
    //对象必须序列化才能存入缓存
    localStorage.setItem(key, JSON.stringify(value));
  },
  // 获取
  getStroage(key) {
    //反序列化
    let data = localStorage.getItem(key);
    if (data == null || data.length == 0) {
      return null;
    } else {
      return JSON.parse(localStorage.getItem(key));
    }
  },
  // 删除
  removeStroage(key) {
    localStorage.removeItem(key);
  },
  getUserOptions() {
    let userOptionsData = this.getStroage("userOptions");
    if (userOptionsData) {
      return userOptionsData;
    } else {
      this.setStroage("userOptions", {});
      return {};
    }
  },
};
export function getNetworkType() {
  let networkState;
  if (navigator.connection) {
    networkState = navigator.connection.type;
    let states = {};
    states[navigator.connection.UNKNOWN] = "unknown";
    states[navigator.connection.ETHERNET] = "ethernet";
    states[navigator.connection.WIFI] = "wifi";
    states[navigator.connection.CELL_2G] = "2G";
    states[navigator.connection.CELL_3G] = "3G";
    states[navigator.connection.CELL_4G] = "4G";
    states[navigator.connection.CELL_5G] = "4G";
    states[navigator.connection.CELL] = "cellular";
    states[navigator.connection.NONE] = "none";
    return states[networkState];
  }
  return "unavailable";
}

export function handleClickOutside(
  instance,
  refName,
  dataProp,
  exceptionRefName
) {
  const handler = (event) => {
    const el = instance.$refs[refName];
    const exceptionEl = instance.$refs[exceptionRefName];

    if (
      el &&
      !el.contains(event.target) &&
      exceptionEl &&
      !exceptionEl.contains(event.target)
    ) {
      instance[dataProp] = false;
    }
  };

  instance.$once("hook:mounted", () => {
    document.addEventListener("click", handler);
  });

  instance.$once("hook:beforeDestroy", () => {
    document.removeEventListener("click", handler);
  });
}
