<template>
  <v-card tile style="opacity: 0.98; z-index: 3">
    <v-app-bar fixed color="header" dark app>
      <div v-show="show_nav_left" class="nav-left">
        <v-toolbar-title style="
            background-color: #353535;
            border-radius: 0.375rem;
            padding: 0.25rem 1rem;
            font-size: 1rem;
          ">
          <router-link to="/index" style="color: inherit">
            {{ websiteName + " " + "Project" }}
          </router-link>
        </v-toolbar-title>
      </div>
      <div class="nav-center">
        <header_search ref="ref_header_search" :searchBoxShow="searchBoxShow" @searchBoxState="searchBoxState">
        </header_search>
      </div>
      <!-- 移动端展开搜索框 -->
      <div class="nav-right" v-show="show_nav_right">
        <v-btn ref="ref_mobileSearchShow" icon class="search-fold" height="40px" width="40px" @click="mobileSearchShow">
          <v-icon size="28">mdi-magnify</v-icon>
        </v-btn>
        <v-btn :to="{ name: replyMeOrSystem }" v-if="isLogin" class="notices" height="40px" width="40px" icon>
          <v-badge color="pink" :value="notices != 0">
            <v-icon size="24">mdi-bell</v-icon>
          </v-badge>
        </v-btn>
        <div class="nav-user" v-show="isLogin">
          <!-- 登录状态显示用户头像和点击弹出框 -->
          <div class="avatar-box" ref="ref_avatar_box" @click="showPopup">
            <div class="avatar" v-lazy-container="{ selector: 'img' }">
              <img :data-src="userAvatar" />
            </div>
            <v-icon>mdi-dots-vertical</v-icon>
            <transition name="slide-fade">
              <header_popup v-show="showUserPopup" ref="ref_header_popup">
              </header_popup>
            </transition>
          </div>
          <!-- 未登录状态显示登录注册 -->
        </div>
        <div class="login d-flex" v-if="!isLogin">
          <router-link :to="{ name: 'Login' }">
            <span>登录</span>
          </router-link>
          <span style="padding: 0 8px; color: #848484">|</span>
          <router-link :to="{ name: 'Register' }">
            <span>注册</span>
          </router-link>
        </div>
      </div>
      <template v-slot:extension>
        <v-row class="g-nav" no-gutters style="
            justify-content: center;
            border-top: 1px solid #313131;
            margin: 0px -20px;
          ">
          <v-btn :to="{ name: 'Index' }" :disabled="isNavThro" @click="navThro" color="white" text rounded class="my-2">
            首页
          </v-btn>
          <v-btn :to="{ name: 'Rank' }" :disabled="isNavThro" @click="navThro" color="white" text rounded class="my-2">热门
          </v-btn>
          <v-btn :to="{ name: 'History' }" :disabled="isNavThro" @click="navThro" ref="ref_history" color="white" text
            rounded class="my-2">
            历史
          </v-btn>
          <v-btn :to="isLogin ? { name: 'Favorite' } : null" :disabled="isNavThro" @click="favoriteNav"
            class="ref_favorite my-2" color="white" text rounded>
            收藏
            <transition name="slide-fade">
              <div class="no-login" ref="ref_no_login" v-show="!isLogin && isShowNologin_f" v-click-outside="{
                handler: closeFavoriteBox,
                include: ref_favorite,
              }
                ">
                <div class="content">登录后即可使用收藏和其他功能</div>
                <v-btn @click="goToLogin">登录</v-btn>
              </div>
            </transition>
          </v-btn>
        </v-row>
      </template>
    </v-app-bar>
  </v-card>
</template>
<script>
import header_popup from "@/components/ui/head/Popup.vue";
import header_search from "@/components/ui/head/HeaderSearch";
import bus from "@/store/bus";
export default {
  mixins: [],
  data() {
    return {
      menu: null,
      websiteName: process.env.VUE_APP_WEBSITE_NAME, // 网站logo名
      searchBoxShow: false, // 搜索弹出框显示
      showUserPopup: false, //  用户信息弹出框显示
      getUserMenuDrawer: null,
      show_nav_left: true,
      show_nav_right: true,
      self_searchBoxState: "",

      // 显示控制
      isShowNologin_h: false,
      isShowNologin_f: false,
      isNavThro: false
    };
  },
  components: {
    header_search,
    header_popup,
  },
  computed: {
    notices() {
      return (
        this.$store.getters.user_info.system_notice +
        this.$store.getters.user_info.reply_notice
      );
    },
    width768() {
      return window.innerWidth <= 768;
    },
    replyMeOrSystem() {
      const systemNoticeCount = this.$store.getters.user_info.system_notice;
      const replyNotice = this.$store.getters.user_info.reply_notice;
      if (systemNoticeCount == 1) {
        return "SysNotice";
      } else if (replyNotice == 1) {
        return "ReplyMe";
      } else {
        return "SysNotice";
      }
    },
  },
  methods: {
    favoriteNav() {
      if (this.isLogin) {
        this.navThro();
      } else {
        this.showLogin('isShowNologin_f');
      }
    },
    navThro() {
      if (this.isNavThro) {
        return;
      }
      this.isNavThro = true;
      setTimeout(() => {
        this.isNavThro = false;
      }, 500)
    },
    closeFavoriteBox() {
      this.isShowNologin_f = false;
    },
    ref_favorite() {
      return [document.querySelector(".ref_favorite")];
    },
    showLogin(data) {
      if (data == "isShowNologin_h") {
        this.isShowNologin_f = false;
      } else {
        this.isShowNologin_h = false;
      }
      this[data] = true;
    },
    showPopup() {
      if (this.isMobile) {
        this.onUserMenuDrawer();
      } else {
        this.showUserPopup = true;
      }
    },
    handleOutsideClick(event) {
      let history = this.$refs.ref_history;
      let favorite = this.$refs.ref_favorite;
      if (!history || !favorite) {
        return;
      }
      if (
        !history.$el.contains(event.target) &&
        !favorite.$el.contains(event.target)
      ) {
        this.isShowNologin_h = false;
        this.isShowNologin_f = false;
      }
    },
    onUserMenuDrawer() {
      this.getUserMenuDrawer = !this.getUserMenuDrawer;
      bus.$emit("getUserMenuDrawer", this.getUserMenuDrawer);
      this.getUserMenuDrawer = !this.getUserMenuDrawer;
    },
    listenerClickHide(ref) {
      /**
       * @function 点击屏幕其他位置后，隐藏用户弹出框
       */
      let _this = this;
      if (this.showUserPopup) {
        this.showUserPopup = false;
      }
    },
    mobileSearchShow() {
      this.searchBoxShow = true;
      this.$nextTick(() => {
        document.querySelector('.search-box__form input').focus();
      })
    },
    // 来自子组件的属性，当前搜索状态
    searchBoxState(val) {
      this.self_searchBoxState = val;
      if (val == "inputDefult") this.searchBoxShow = false;
    },
  },
  created() {
  },
  mounted() {
    if (this.$refs.ref_header_popup)
      this.handleStopPropagation([
        this.$refs.ref_header_popup.$el,
        this.$refs.ref_avatar_box,
        this.$refs.ref_mobileSearchShow.$el,
      ]);
    document.addEventListener("click", this.listenerClickHide);
  },
  destroyed() { },
  watch: {
    self_searchBoxState() {
      switch (this.self_searchBoxState) {
        case "inputDefult":
          this.show_nav_left = true;
          this.show_nav_right = true;
          break;
        case "inputFocus":
          if (window.innerWidth > 768) {
            this.show_nav_left = true;
            this.show_nav_right = true;
          } else {
            this.show_nav_left = false;
            this.show_nav_right = false;
          }
          break;
        case "inputItemFocus":
          if (window.innerWidth > 768) {
            this.show_nav_left = true;
            this.show_nav_right = true;
          } else {
            this.show_nav_left = false;
            this.show_nav_right = false;
          }
          break;
        default:
          console.error("Invalid searchBoxState value");
      }
    },
    screenWidth(newWidth) {
      switch (this.self_searchBoxState) {
        case "inputDefult":
          this.show_nav_left = true;
          this.show_nav_right = true;
          break;
        case "inputFocus":
          if (newWidth > 768) {
            this.show_nav_left = true;
            this.show_nav_right = true;
          } else {
            this.show_nav_left = false;
            this.show_nav_right = false;
          }
          break;
        case "inputItemFocus":
          if (newWidth > 768) {
            this.show_nav_left = true;
            this.show_nav_right = true;
          } else {
            this.show_nav_left = false;
            this.show_nav_right = false;
          }
          break;
        default:
          console.error("Invalid searchBoxState value");
      }
    },
  },
};
</script>
<style lang="scss">
.theme--dark.v-btn.v-btn--disabled {
  color: rgba(255, 255, 255, 0.7) !important;
}

.g-nav {
  .v-btn {
    &::before {
      content: none;
    }
  }

  .v-btn--active {
    &::before {
      content: "" !important;
      opacity: 1 !important;
      color: #e91e63;
      top: auto;
      height: 2px;
    }
  }

  .v-ripple__container {
    display: none;
  }
}

.v-btn--active {
  &::before {
    opacity: 1 !important;
    color: #e91e63;
    top: auto;
    height: 2px;
  }
}

.no-login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 42px;
  z-index: 10;
  background-color: #535353;
  border-color: rgba(255, 255, 255, 0.1) rgba(255, 255, 255, 0.07) rgba(255, 255, 255, 0.04);
  border-radius: 0.25rem;
  border-style: solid;
  border-width: 1px;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2),
    0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  padding: 1.6rem;

  @media (max-width: 440px) {
    position: fixed;
    left: 50%;
    top: 116px;
    transform: translateX(-50%);
  }

  .content {
    margin-bottom: 20px;
  }

  .v-btn {
    width: 100%;
    background-color: #ec4e83 !important;
  }
}

.v-toolbar__content {
  justify-content: space-between;

  @media (max-width: 768px) {
    padding: 4px 12px !important;
  }

  .nav-left {
    display: flex;
    flex-direction: row;
    align-items: center;

    .v-btn {
      width: 36px !important;
      height: 36px !important;
    }

    .g-menu {
      width: auto !important;
      justify-content: left;
      margin-right: 12px;
    }

    .v-toolbar__title {
      height: max-content;

      @media (max-width: 768px) {}
    }
  }

  /* 搜索框 */
  .nav-center {
    flex: 0 1 560px;
    height: 38px;

    @media (min-width: 768px) {
      display: block !important;
    }

    @media (max-width: 1024px) {
      flex: 0 1 430px;
    }

    @media (max-width: 768px) {
      flex: 1;
    }
  }

  .nav-right {
    display: flex;
    align-items: center;

    .v-btn {}

    .nav-user {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      .avatar-box {
        display: flex;
        flex-direction: row;
        position: relative;
        margin-left: 8px;

        @media (max-width: 768px) {
          margin-left: 0;
        }

        .avatar {
          width: 36px;
          height: 36px;
          cursor: pointer;
          border-radius: 50%;
          background-color: transparent;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            display: block;
          }
        }

        .v-icon {
          margin-left: -4px;
        }
      }
    }

    .login {
      padding: 4px 10px;
      border-radius: 20px;
      background-color: #353535;
      border-color: rgba(255, 255, 255, 0.1) rgba(255, 255, 255, 0.07) rgba(255, 255, 255, 0.04);
      border-style: solid;
      border-width: 1px;
    }

    .login a {
      color: #c9c9c9;
    }

    .search-fold {
      margin-right: 4px;
      margin-left: auto;

      @media (min-width: 769px) {
        display: none;
      }
    }

    .notices {
      margin-right: 4px;

      &::before {
        content: none;
      }

      @media (max-width: 768px) {
        margin-right: 8px;
      }
    }
  }
}
</style>
