import bus from "@/store/bus";
import { } from "@/utils/tools";
export default {
  data() {
    return {
      viewErrImg: require("@/assets/viewErrImg.jpg"),
      userErrImg: require("@/assets/defultAvatar.jpg"),
      errorImg: require("@/assets/viewErrImg.svg"),
      requestWaitTime: 200,
    };
  },
  computed: {
    isMobile() {
      if (this.screenWidth <= 768) {
        return true;
      } else {
        return false;
      }
    },
    screenWidth() {
      return this.$store.state.stateControl.screenWidth;
    },
    isMobileDevice() {
      return this.$store.state.stateControl.isMobileDevice;
    },
    userAvatar() {
      return this.$store.getters.userAvatar;
    },
    isLogin() {
      return this.$store.getters.isLogin;
    },
    resource_category() {
      return this.$store.state.stateControl.resource_category;
    },
    // 环境变量，是否是游客模式
    TOURIST_MODE() {
      return process.env.VUE_APP_TOURIST_MODE;
    },
  },
  methods: {
    whereTo(category) {
      switch (category) {
        case "video":
          return "AnimeInfo";
        case "comic":
          return "ComicInfo";
        case "novel":
          return "NovelInfo";
        case "sound":
          return "SoundInfo";
        default:
          return "";
      }
    },
    dataListIcon(category) {
      switch (category) {
        case "comic":
          return "mdi-image";
        case "video":
          return "fa fas fa-play-circle";
        case "novel":
          return "mdi-book-open-page-variant";
        case "sound":
          return "mdi-music";
        default:
          return "mdi-view-list";
      }
    },
    getRouteName() {
      const url = window.location.href;
      const regex = /https?:\/\/[^/]+(\/[^?]+)/;
      const match = url.match(regex);

      if (match) {
        const route = match[1];
        return route;
      } else {
        return url;
      }
    },
    typeName(category) {
      switch (category) {
        case "comic":
          return "漫画";
        case "video":
          return "动漫";
        case "novel":
          return "轻小说";
        case "sound":
          return "音声";
      }
    },
    noBack() {
      if (window.history && window.history.pushState) {
        window.onpopstate = function () {
          window.history.pushState("forward", null, "");
          window.history.forward(1);
        };
      }
      window.history.pushState("forward", null, ""); //在IE中必须得有这两行
      window.history.forward(1);
    },
    uniqueId: (prefix) =>
      `${prefix}_` + Math.random().toString(36).substr(2, 9),
    handleError(error, toTips = true, goToLogin) {
      // 判断登录是否过期，如果过期则清空本地和vuex的登录数据
      if (error?.info?.message == '登陆已失效，请重新登陆。') {
        this.clearLoginStatus();
      }
      if (toTips != true) return;
      bus.$emit("errorMessage", {
        id: this.uniqueId("item_"),
        color: "error",
        message: error?.info?.message ?? "网络错误",
      });
    },
    clearLoginStatus() {
      // 以下路由不需要处理
      if (
        [
          "/login",
          "/forget",
          "/register",
          "/user/changepassword",
          "/restricte_daccess",
        ].includes(this.getRouteName())
      ) {
        return;
      }

      // 清除vuex用户信息
      this.$store.commit("edit_user_info", null);
      // 清空本地存储
      localStorage.setItem("local_user_info", null);
      // 修改登录状态为未登录
      sessionStorage.setItem("isLogin", false);
      this.$store.commit("edit_isLogin", false);
      // 删除jwt
      localStorage.removeItem("jwt");
    },
    goToLogin() {
      this.$router.push("/login");
      bus.$emit("snackbar", {
        show: true,
        content: "请登录",
      });
    },
    /**
     * @function 除去对象中指定属性，并将其余的属性值用空格拼接
     * @param {string[]} exceptProps - 指定属性名数组
     * @param {object} obj - 对象
     * @returns {string} 将其余属性值用空格拼接
     */
    concatObjProps(exceptProps, obj) {
      const props = Object.keys(obj)
        .filter((prop) => !exceptProps.includes(prop))
        .map((prop) => obj[prop].toString());
      return props.join(" ");
    },
    /**
     * @function 根据值删除属性
     * @param {string} val - 指定值
     * @param {object} obj - 对象
     */
    deleteEmptyProps(val, obj) {
      const filteredEntries = Object.entries(obj).reduce(
        (acc, [key, value]) => {
          if (value !== "" && value !== val) {
            acc.push([key, value]);
          }
          return acc;
        },
        []
      );
      return Object.fromEntries(filteredEntries);
    },
    /**
     * @function 阻止事件冒泡
     */
    listenerStopPropagation(e) {
      e.stopPropagation();
    },
    handleStopPropagation(ref) {
      let _this = this;
      if (ref != undefined) {
        ref.map((el) => {
          if (el) {
            if (Array.isArray(el)) {
              el.map((child) => {
                child.removeEventListener(
                  "click",
                  this.listenerStopPropagation
                );
                child.addEventListener("click", this.listenerStopPropagation);
              });
            } else {
              el.removeEventListener("click", this.listenerStopPropagation);
              el.addEventListener("click", this.listenerStopPropagation);
            }
          }
        });
      }
    },
    findObjInMultArrayById(arr, id) {
      for (let i = 0; i < arr.length; i++) {
        const innerArr = arr[i];
        for (let j = 0; j < innerArr.length; j++) {
          if (innerArr[j].id === id) {
            return innerArr[j];
          }
        }
      }
      return null; // 如果未找到匹配的对象，则返回 null 或者其他你认为合适的值
    },
    findObjectById(data, id) {
      for (let i = 0; i < data.length; i++) {
        const page = data[i];

        for (let j = 0; j < page.length; j++) {
          const obj = page[j];

          if (obj.id === id) {
            return [i, j]; // 返回页和对象位置的索引数组
          }
        }
      }
      return null; // ID 未找到
    },
    listenerScreenWidth() {
      this.$store.commit("edit_screenWidth", window.innerWidth);
      this.$store.commit(
        "edit_isMobileDevice",
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      );
    },
  },
  mounted() { },
  beforeDestroy() { },
};
