<template>
  <div id="app">
    <v-app>
      <!-- 顶部导航 -->
      <component_head v-if="!dontShowHeader.includes($route.path)"></component_head>
      <Footer :value="announcement" @update:value="announcement = $event" v-if="$route.path == '/index'"></Footer>

      <!-- 左侧抽屉导航 -->
      <userMenuDrawer></userMenuDrawer>

      <keep-alive :include="aliveComponents">
        <router-view ref="router_view" v-if="isRouterAlive"></router-view>
      </keep-alive>

      <!-- 右上角报错提示消息 -->
      <v-snackbar-queue class="err-snackbar" :items="items" tile color="red accent-2" top right @remove="removeItem"
        :timeout="2000"></v-snackbar-queue>

      <!-- 中下提示消息 -->
      <v-snackbar class="message-snackbar" v-model="isShowSnackbar" timeout="2000" vertical>
        {{ snackbarText }}
      </v-snackbar>

      <!-- 公告 -->
      <Announcement :value="announcement" @update:value="announcement = $event" :closeAnnouncement="closeAnnouncement">
      </Announcement>

      <div class="fab" v-if="!dontShowHeader.includes($route.path)">
        <v-btn color="pink" fab v-throttle:600="() => ($route.meta.isInfo ? routerBack() : reload())">
          <v-icon>{{
            $route.meta.isInfo ? "mdi-chevron-left" : "mdi-reload"
          }}</v-icon>
        </v-btn>
      </div>
    </v-app>
  </div>
</template>
<script>
import throttle from "lodash/throttle";
import userMenuDrawer from "@/components/ui/navigation/UserMenuDrawer";
import component_head from "@/components/ui/head/Index";
import Footer from "@/components/ui/foot/Index";
import Announcement from "@/components/ui/features/announcement/Announcement";
import { get_User } from "@/api/request/user";
import bus from "@/store/bus";
export default {
  name: "App",
  data() {
    return {
      items: [],
      snackbarText: "",
      isShowSnackbar: "",
      direction: "ltr",
      currentRoute: "",
      isRouterAlive: true,
      aliveComponents: ["index", "search", "rank", "history", "favorite"],
      dontShowHeader: [
        "/login",
        "/forget",
        "/register",
        "/user/changepassword",
        "/user/email_verify/",
        "/restricte_daccess",
      ],
      announcement: false, // 公告
    };
  },
  computed: {},
  provide() {
    return {
      reload: this.reload,
    };
  },
  components: {
    component_head,
    Footer,
    userMenuDrawer,
    Announcement,
  },
  methods: {
    async init() {
      // 获取本地的用户信息
      let local_user_info = this.$storage.getStroage("local_user_info");
      // 如果没有数据则将将用户信息清空，并将登录状态设置为未登录
      if (local_user_info != null) {
        // 如果有数据则添加进vuex，并将登录状态设置为已登录
        this.$store.commit("edit_isLogin", true);
        this.$store.commit("edit_user_info", local_user_info);
        this.$store.commit("edit_user_avatar", local_user_info.avatar);
      }

      if (!this.dontShowHeader.includes(this.getRouteName())) {
        // 获取用户信息
        await this.get_User();

        let storage = this.$storage.getUserOptions();
        if (storage.announcementTime) {
          const nowTime = Date.now();
          const announcementTime = storage.announcementTime;

          const millisecondsPerDay = 24 * 60 * 60 * 1000; // 一天的毫秒数
          const timeDiff = nowTime - announcementTime; // 时间差

          if (timeDiff >= millisecondsPerDay) {
            this.announcement = true;
          } else {
            this.announcement = false;
          }
        } else {
          this.announcement = true; // 没有数据时设置为 true
        }
      }
    },
    disableZoom() {
      document.addEventListener(
        "touchstart",
        function (event) {
          if (event.touches.length > 1) {
            if (event.cancelable) event.preventDefault();
          }
        },
        { passive: false }
      );

      // 阻止手势缩放
      document.addEventListener("gesturestart", function (event) {
        if (event.cancelable) event.preventDefault();
      });

      // 禁止双击屏幕缩放
      let lastTouchEnd = 0;
      document.addEventListener(
        "touchend",
        function (event) {
          let now = new Date().getTime();
          if (now - lastTouchEnd <= 300) {
            if (event.cancelable) event.preventDefault();
          }
          lastTouchEnd = now;
        },
        false
      );
    },
    closeAnnouncement() {
      this.announcement = false;
      let storage = this.$storage.getUserOptions();
      storage.announcementTime = Date.now();
      this.$storage.setStroage("userOptions", storage);
    },
    getRouteName() {
      const url = window.location.href;
      const regex = /https?:\/\/[^/]+(\/[^?]+)/;
      const match = url.match(regex);

      if (match) {
        const route = match[1];
        return route;
      } else {
        return url;
      }
    },
    routerBack() {
      if (this.$route.fullPath != "/index") {
        this.$router.back();
      } else {
        bus.$emit("snackbar", {
          show: true,
          content: "当前已是首页",
        });
      }
    },
    reload() {
      this.aliveComponents = [];
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.aliveComponents = [
          "index",
          "search",
          "rank",
          "history",
          "favorite",
        ];
        this.isRouterAlive = true;
        this.$nextTick(() => {
          window.scrollTo(0, 0);
        });
      });
    },
    removeItem(id) {
      const vm = this;
      const index = vm.items.findIndex((item) => item.id === id);
      if (index !== -1) {
        vm.items.splice(index, 1);
      }
    },
    uniqueId: (prefix) =>
      `${prefix}_` + Math.random().toString(36).substr(2, 9),
    handleClose(done) {
      done();
    },
    get_User() {
      return get_User.bind(this)();
    },
  },
  async created() {
    // 报错弹窗
    bus.$on("errorMessage", (data) => {
      this.items.push(data);
    });
    // 底部消息弹出
    bus.$on("snackbar", (data) => {
      this.isShowSnackbar = data.show;
      this.snackbarText = data.content;
    });
    await this.init();
  },
  mounted() {
    this.disableZoom();
    const handler = throttle(() => {
      this.listenerScreenWidth();
    }, 300);
    window.addEventListener("resize", handler);
  },
  watch: {},
};
</script>
<style lang="scss" scoped>
::v-deep .message-snackbar {
  .v-snack {
    @media (max-width: 768px) {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      justify-content: center;
      margin: 0;
    }

    .v-snack__wrapper {
      margin: 130px 30px 8px 8px;

      @media (max-width: 768px) {
        margin: 0 0 60px 0 !important;
      }
    }
  }
}

::v-deep .err-snackbar {
  .v-snack {
    @media (max-width: 768px) {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      justify-content: center;
      margin: 0;
    }

    .v-snack__wrapper {
      margin: 130px 30px 8px 8px;

      @media (max-width: 768px) {
        margin: 120px 0 0 0 !important;
      }
    }
  }
}

@media (min-width: 768px) {
  .navigation {
    display: none;
  }

  .g-menu {
    display: none;
  }
}

::v-deep .v-snack__content {
  text-align: center;
}

</style>
