import Vue from 'vue';
import Vuex from 'vuex'
Vue.use(Vuex);
import data from "./data";
import user from "./user";
import comment from "./comment";
import viewList from "./viewList";
import stateControl from "./stateControl";
export default new Vuex.Store({
    modules: {
        data,
        user,
        comment,
        stateControl,
        viewList
    }
});