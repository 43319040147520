import Vue from "vue";
import bus from "@/store/bus";

Vue.directive("throttle", {
  bind: function (el, binding) {
    const fn = binding.value;
    const delay = binding.arg || 500;
    let timer = null;
    let lastExecutedTime = null;
    let isThrottled = false;
    el.addEventListener("click", function (event) {
      const args = arguments;
      const context = this;
      const currentTime = new Date().getTime();
      if (!lastExecutedTime || currentTime - lastExecutedTime >= delay) {
        fn.apply(context, args);
        lastExecutedTime = currentTime;
        if (isThrottled) {
          isThrottled = false;
          bus.$emit("snackbar", {
            show: true,
            content: "操作太快啦，休息一下吧！",
          });
        }
      } else {
        if (!isThrottled) {
          isThrottled = true;
          bus.$emit("snackbar", {
            show: true,
            content: "操作太快啦，休息一下吧！",
          });
        }
      }
      clearTimeout(timer);
      timer = setTimeout(function () {
        lastExecutedTime = new Date().getTime();
        isThrottled = false;
      }, delay - (currentTime - lastExecutedTime));
    }, false);
  },
});

