import '@mdi/font/css/materialdesignicons.css';
import '@fortawesome/fontawesome-free/css/all.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'fa', // 默认值 - 仅用于展示目的
  },
  // 添加主题配置
  theme: {
    dark: true, // 如果指定为 true，那么整个页面主题将为下面的 dark 配色
    themes: {
      light: {
        primary: '#3f51b5',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        header: '#3f51b5',
      },
      dark: {
        primary: '#e91e63',
        info: '#e91e63',
        header: '#212121',
      },
    },
  },
});