import requests from "./request";
//  用户登录
export const reqUserLogin = (data) =>
  requests({ url: "user/login/", data, method: "post" });
export const reqGetCode = (data) =>
  requests({ url: "captcha/generate/", data, method: "post" });
export const reqUserRegister = (data) =>
  requests({ url: "user/register/", data, method: "post" });
export const reqUserForget = (data) =>
  requests({ url: "user/findpassword/", data, method: "post" });
export const exit = (data) =>
  requests({ url: "user/logout/", data, method: "post" });
//  获取数据
export const get_Data = (data) =>
  requests({ url: "get/list/", data, method: "post" });
export const get_category_list = (data) =>
  requests({ url: "get/category/", data, method: "post" });
export const get_Info_Data = (data) =>
  requests({ url: "get/info/", data, method: "post" });
export const get_Chapter_Data = (data) =>
  requests({ url: "get/episode/", data, method: "post" });
export const get_Chapter_Page = (data) =>
  requests({ url: "get/episode/page/", data, method: "post" });
export const get_rank = (data) =>
  requests({ url: "get/rank/", data, method: "post" });

//  历史记录与收藏

export const get_history = (data) =>
  requests({ url: "get/history/", data, method: "post" });
export const get_favorite = (data) =>
  requests({ url: "get/favorite/", data, method: "post" });

//  资源反馈
export const suggestion = (data) =>
  requests({ url: "post/user/suggestion/", data, method: "post" });

//  搜索
export const get_Search_Data = (data) =>
  requests({ url: "search/", data, method: "post" });
export const get_Hot_Word = (data) =>
  requests({ url: "search/popularkeywords/", data, method: "post" });

//  个人中心
export const get_User = (data) =>
  requests({ url: "get/user/", data, method: "post" });
export const get_notice = (data) =>
  requests({ url: "get/system/message/", data, method: "post" });
export const get_Reply_notice = (data) =>
  requests({ url: "get/reply/", data, method: "post" });
export const edit_user_name = (data) =>
  requests({ url: "edit_user_name", data, method: "post" });
export const email_verify_send = (data) =>
  requests({ url: "user/emailverify/send/", data, method: "post" });

export const email_verify = (data) =>
  requests({ url: "user/emailverify/verify/", data, method: "post" });
export const user_change_password = (data) =>
  requests({ url: "user/changepassword/", data, method: "post" });

//  上传头像文件
export const upload_Avatar = (data) =>
  requests({ url: "user/avatar/upload/", data, method: "post" });
export const apply_change_Avatar = (data) =>
  requests({ url: "user/avatar/apply/", data, method: "post" });
export const verify_Avatar = (data) =>
  requests({ url: "user/avatar/verify/", data, method: "post" });
//  评论
export const create_comment = (data) =>
  requests({ url: "comment/create/", data, method: "post" });
export const get_comment = (data) =>
  requests({ url: "comment/get/", data, method: "post" });
export const report_Comment = (data) =>
  requests({ url: "post/user/report/", data, method: "post" });

//  用户操作资源
export const add_favorite = (data) =>
  requests({ url: "favorite/add/", data, method: "post" });
export const del_favorite = (data) =>
  requests({ url: "favorite/delete/", data, method: "post" });
export const like = (data) => requests({ url: "like/", data, method: "post" });
